import { axiosPrivate } from "./axios";
import { useEffect } from "react";
import useRefreshToken from "hooks/useRefreshToken";
import useAuth from "hooks/useAuth";

const useAxiosPrivate = (props) => {
  // console.log(props);
  const refresh = useRefreshToken();
  const { auth, setAuth } = useAuth();
  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      async (config) => {
        let token = localStorage.getItem("accessToken");
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      },
      (err) => {
        console.log("error in getting ", err);
      }
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401) {
          prevRequest.sent = true;
          const newToken = await refresh(auth, setAuth);
          prevRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axiosPrivate.interceptors.response.eject(responseIntercept);
      axiosPrivate.interceptors.request.eject(requestIntercept);
    };
  }, [auth, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
