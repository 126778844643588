
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import { useRef, useState, useEffect } from "react";
import { useLocation, useHistory, Link, Redirect } from "react-router-dom";
import useAuth from "hooks/useAuth";
import axios from "api/axios";
import { toast } from "react-toastify";
const LOGIN_URL = "/identity/login";

const Login = () => {
  const { setAuth, auth, persist, setPersist, tenant, fetchTenant } = useAuth();

  const navigate = useHistory();
  const location = useLocation();
  const from = location?.state?.from?.pathname || "/";

  const emailRef = useRef();
  // const errRef = useRef();

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [isSending, setIsSending] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    fetchTenant();
    emailRef.current.focus();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSending) return;
    const toastId = toast.loading("Logging In...");
    try {
      setIsSending(true);
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({
          username: email,
          password: pwd,
          tenantId: tenant.tenantId,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response?.status >= 200 && response?.status < 300) {
        console.log("login response", JSON.stringify(response?.data));
        const accessToken = response?.data?.data.authToken;
        const refreshToken = response?.data?.data.refreshToken;
        const username = response?.data?.data.username;
        const roleId = response?.data?.data.role;
        const userId = response.data.data.id;
        toast.update(toastId, {
          render: "Logged In Sucessfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        setAuth({ accessToken, refreshToken, username, roleId, userId });
        setEmail("");
        setPwd("");
        //if checkbox then save to local storage
        if (true) {
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("roleId", roleId);
          localStorage.setItem("userId", userId);
          localStorage.setItem("username", username);
        }
        navigate.push(from); //("/admin/index");
      } else {
        toast.update(toastId, {
          render: response.data,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        err = "No Server Response!";
        setErrMsg(err);
      } else if (err.response?.status === 400) {
        // err = "Missing username or password";
        err = err?.response?.data?.message;
        setErrMsg(err);
      } else if (err.response?.status === 401) {
        err = "Unauthorized";
        setErrMsg(err);
      } else {
        err = "Login Failed!";
        setErrMsg(err);
      }
      toast.update(toastId, {
        render: err,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      //errRef.current.focus();
    } finally {
      if (isMounted.current) {
        setIsSending(false);
      }
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <>
      {auth?.accessToken && (
        <Redirect
          to={{
            pathname: "/auth/login",
          }}
        />
      )}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          {/* <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/github.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardHeader> */}
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in with credentials</small>
            </div>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="text"
                    autoComplete="off"
                    id="email"
                    ref={emailRef}
                    onFocus={(e) => {
                      console.log(e.target.value);
                      setEmail(e.target.value);
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="off"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                  />
                </InputGroup>
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  onChange={togglePersist}
                  checked={persist}
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button className="my-4" color="primary" disabled={isSending}>
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          {/* <Col className="text-right" xs="6">
            <Link
              className="text-light"
              to="/auth/register"
            //onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </Link>
          </Col> */}
        </Row>
      </Col>
    </>
  );
};

export default Login;
