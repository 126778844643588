import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Col,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  ButtonDropdown,
  Input,
  CardBody,
  Table,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "api/useAxiosPrivate";
import swal from "sweetalert";
import AddOperatorFetchModal from "components/Modals/AddOperatorFetchModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const ConfigurationOperatorFetch = () => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };
  const [apis, setApis] = useState([]);
  const [data, setData] = useState({});
  const [selectedApi, setSelectedApi] = useState("0");
  const [methodType, setMethodType] = useState("get");
  const [isSubmit, setIsSubmit] = useState(false)
  const { balance } = useAuth();
  const [params, setParams] = useState([
    {
      key: "",
      value: "",
      dynamic: false,
    },
    {
      key: "",
      value: "",
      dynamic: false,
    },
    {
      key: "",
      value: "",
      dynamic: false,
    },
    {
      key: "",
      value: "",
      dynamic: false,
    },
    {
      key: "",
      value: "",
      dynamic: false,
    },

  ]);
  const paramsKeys = [1, 2, 3, 4, 5];

  const rechargeDynamicValues = [
    { label: "Static Value", value: "" },
    { label: "Circle ID", value: "{CIRCLEID}" },
    { label: "Mobile Number", value: "{MOBILE}" },
  ];

  const handleParamChange = (name, index, valueType = "key") => {
    const temp = [...params];
    if (valueType === "key") {
      temp[index].key = name;
    } else {
      temp[index].value = name;
    }
    if (valueType === "dynamic" && name === "") {
      temp[index].dynamic = false;
    } else if (valueType === "dynamic" && name !== "") {
      temp[index].dynamic = true;
    }
    setParams(temp);
  };


  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const fetchOperatorsPlanList = async () => {
    try {
      const response = await axiosPrivate.get("/extras/operator-fetch/list", {
        AbortSignal: controller.signal,
      });
      response.data.list && setApis(response.data.list);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchOperatorsPlan = async () => {
    try {
      const response = await axiosPrivate.get("/extras/operator-fetch/configuration", {
        AbortSignal: controller.signal,
      });
      response.data?.data && setSelectedApi(response.data.data.fetchApiId)
      response.data?.data && setMethodType(response.data.data.methodType)
      response.data?.data && setData(response.data.data)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // if (!balance) return;

    Promise.all([fetchOperatorsPlan(), fetchOperatorsPlanList()]);
    return () => {
      controller.abort();
    };
  }, [balance]);

  useEffect(() => {
    if (!data) return;
    //setup params
    setParams(paramsKeys.map((key, index) => {
      if (key <= data?.params?.length) {
        return data.params[index]
      }
      return {
        key: "",
        value: "",
        dynamic: false,
      }
    }))
  }, [data]);

  const saveAPI = async () => {
    if (isSubmit) return;
    setIsSubmit(true);
    if (!selectedApi) {
      swal("Select All Details!", "", "error")
      return setIsSubmit(false)
    }
    try {
      const response = await axiosPrivate.post("/extras/operator-fetch/set", {
        fetchApiId: selectedApi,
        methodType: methodType,
        params: params.filter(item => item.key != "" && item.value != "")
      });
      if (response.status === 200 && response.data.success) {
        swal("success", "Operator Fetch Configured Successfully!", "success");
      }
    } catch (err) {
      swal("error", err.message);
    } finally {
      setIsSubmit(false)
    }
  }


  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Configure Operator Fetch</h3>
              </CardHeader>
              <CardBody>
                <Card className="shadow">
                  <CardBody>
                    <Row md={3}>
                      <Col>
                        <FormGroup>
                          <label htmlFor="name">Operator Fetch API*</label>
                          <Input type="select"
                            size={"sm"}
                            name="operator-fetch-api"
                            id="operator-fetch-api"
                            value={selectedApi}
                            onChange={(e) => setSelectedApi(e.target.value)}
                          >
                            <option key="0" value="0">
                              Select Operator Fetch
                            </option>
                            {apis.map((item, index) => {
                              return <option key={index + 1} value={item._id}>{item.name}</option>
                            })}
                          </Input>
                        </FormGroup>

                      </Col>
                      <Col>
                        <FormGroup>
                          <label htmlFor="req-type">
                            Request Type
                          </label>
                          <Input
                            type="select"
                            name="req-type"
                            id="req-type"
                            value={methodType}
                            onChange={(e) =>
                              setMethodType(e.target.value)
                            }
                          >
                            <option value="get">GET</option>
                            <option value="post">POST</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Parameter List</label>
                        <Table bordered>
                          <thead>
                            <tr>
                              <th>Param Name</th>
                              <th>Dynamic Value</th>
                              <th>Static Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paramsKeys.map((item, index) => {
                              return (
                                <tr key={item}>
                                  <td
                                    style={{
                                      padding: "10px",
                                      height: "30px",
                                    }}
                                  >
                                    <Input
                                      type="text"
                                      style={{ height: "30px" }}
                                      name={`param${item}`}
                                      id={`param${item}`}
                                      placeholder="Key"
                                      value={params[index].key}
                                      onChange={(e) =>
                                        handleParamChange(
                                          e.target.value,
                                          index,
                                          "key"
                                        )
                                      }
                                    />
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      paddingBottom: "10px",
                                      height: "40px",
                                    }}
                                  >
                                    <select
                                      className="form-control-sm form-control"
                                      onChange={(e) =>
                                        handleParamChange(
                                          e.target.value,
                                          index,
                                          "dynamic"
                                        )
                                      }
                                      value={
                                        data && params[index].dynamic
                                          ? params[index].value
                                          : paramsKeys[0].value
                                      }
                                    >
                                      {rechargeDynamicValues.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      paddingBottom: "10px",
                                      height: "40px",
                                    }}
                                  >
                                    <Input
                                      style={{ height: "30px" }}
                                      type="text"
                                      disabled={params[index].dynamic}
                                      name={`value${item}`}
                                      id={`value${item}`}
                                      placeholder="Value"
                                      value={
                                        !params[index].dynamic
                                          ? params[index].value
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleParamChange(
                                          e.target.value,
                                          index,
                                          "value"
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <Row>
                      <span style={{ float: "none", margin: "0 auto" }}>
                        <Button
                          color="primary"
                          type="button"
                          onClick={saveAPI}
                          disabled={isSubmit}
                        >
                          Save
                        </Button>
                      </span>
                    </Row>
                  </CardBody>
                </Card>


              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfigurationOperatorFetch;
