import useAxiosPrivate from "api/useAxiosPrivate";
import { serviceTypes } from "config/constaints";
// import SlabMasterCommissionDetailTable from "components/Tables/SlabMasterCommissionDetailTable";
import useAuth from "hooks/useAuth";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Input,
  ModalFooter,
  Row,
  Col,
  Label,
} from "reactstrap";
import swal from "sweetalert";

const AddBankModel = ({ show, toggle, data, fetchBank }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [bankName, setBankName] = useState(data?.bankName ? data.bankName : "");
  const [branchname, setBranchname] = useState(
    data?.branchName ? data.branchName : ""
  );
  const [accountname, setAccountname] = useState(
    data?.accountName ? data.accountName : ""
  );
  const [accountnumber, setAccountNumber] = useState(
    data?.accountNumber ? data.accountNumber : ""
  );
  const [ifscCode, setIfscCode] = useState(data?.ifscCode ? data.ifscCode : "");
  const [accountType, setAccounttype] = useState(
    data?.accountType ? data.accountType : "Saving"
  );
  const [remarks, setRemarks] = useState(data?.remarks ? data.remarks : "");
  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });
  const { auth } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const submitForm = async (e) => {
    e.preventDefault();
    if (isSubmit) return;
    try {
      setIsSubmit(true);
      if (
        !bankName ||
        !branchname ||
        !accountname ||
        !accountnumber ||
        !ifscCode
      ) {
        await swal({
          title: "Required Fields Missing!",
          icon: "warning",
        });
        setIsSubmit(false);
        return;
      }
      const payload = {
        branchName: branchname,
        accountName: accountname,
        accountNumber: accountnumber,
        ifscCode,
        bankName,
        accountType,
        remarks,
      };
      const response = await axiosPrivate.post(
        data?._id ? "/bank/" + data._id : "/bank/add",
        payload
      );
      if (
        response?.status >= 200 &&
        response?.status < 300 &&
        response?.data?.success === true
      ) {
        swal(
          "Bank " + (data?._id ? "Updated" : "Added") + " Sucessfully!",
          "",
          "success"
        );
        toggle();
        fetchBank();
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err?.response ? err.response : err.data);
      swal("Something went wrong!", "", "error");
    } finally {
      if (isMounted.current) {
        setIsSubmit(false);
      }
    }
  };
  var packageId = "1";
  return (
    <div>
      <Modal isOpen={show} backdrop="static" keyboard toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Add Bank</ModalHeader>
        <ModalBody>
          <Row md={2}>
            <Col md="5">
              <FormGroup>
                <label htmlFor="input-bank">Bank Name*</label>
                <Input
                  className="form-control"
                  type=""
                  defaultValue={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  id="input-bank"
                  name="bank"
                  placeholder="Enter Bank Name"
                />
              </FormGroup>
            </Col>
            <Col md="5">
              <FormGroup>
                <label htmlFor="input-bank">Branch Name*</label>
                <Input
                  className="form-control"
                  type=""
                  defaultValue={branchname}
                  onChange={(e) => setBranchname(e.target.value)}
                  id="input-branchname"
                  name="branchname"
                  placeholder="Enter Branch Name"
                />
              </FormGroup>
            </Col>
            <Col md="5">
              <FormGroup>
                <label htmlFor="input-bank">Account Name*</label>
                <Input
                  className="form-control"
                  type=""
                  defaultValue={accountname}
                  onChange={(e) => setAccountname(e.target.value)}
                  id="input-accountname"
                  name="accountname"
                  placeholder="Enter Account Name"
                />
              </FormGroup>
            </Col>
            <Col md="5">
              <FormGroup>
                <label htmlFor="input-bank">Account No.*</label>
                <Input
                  className="form-control"
                  type=""
                  defaultValue={accountnumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  id="input-accountnumber"
                  name="accountnumber"
                  placeholder="Enter Account Number"
                />
              </FormGroup>
            </Col>
            <Col md="5">
              <FormGroup>
                <label htmlFor="input-bank">IFSC Code*</label>
                <Input
                  className="form-control"
                  type=""
                  defaultValue={ifscCode}
                  onChange={(e) => setIfscCode(e.target.value)}
                  id="input-ifsc"
                  name="ifsc"
                  placeholder="Enter IFSC Code"
                />
              </FormGroup>
            </Col>
            <Col md="5">
              <FormGroup>
                <label htmlFor="input-bank">Account Type</label>
                <select
                  className="form-control"
                  type=""
                  defaultValue={accountType}
                  onChange={(e) => setAccounttype(e.target.value)}
                  id="input-acc-type"
                  name="acc-type"
                >
                  <option key="0" value={"Saving"}>
                    Saving
                  </option>
                  <option key="1" value={"Current"}>
                    Current
                  </option>
                </select>
              </FormGroup>
            </Col>
            <Col md="5">
              <FormGroup>
                <label htmlFor="input-bank">Remarks</label>
                <Input
                  className="form-control"
                  type=""
                  defaultValue={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  id="input-remarks"
                  name="remarks"
                  placeholder="Enter Bank Remarks"
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => submitForm(e)}>
            {data?._id ? "Update" : "Add"}
          </Button>{" "}
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddBankModel;
