import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Col,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  ButtonDropdown,
  Badge,
  Input,
  CardFooter,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "api/useAxiosPrivate";
import swal from "sweetalert";
import { serviceTypes } from "config/constaints";
import Spinner from "react-bootstrap/Spinner";

var operatorTypes = {};
serviceTypes.forEach((item) => {
  operatorTypes[item.toLowerCase()] = item;
});
const InwardMarginTable = () => {
  const [data, setData] = useState([]);
  const [operators, setOperators] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [serviceType, setServiceType] = useState("mobile");
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const fetchAPIList = async () => {
    try {
      const response = await axiosPrivate.get("/margin/inward/list", {
        AbortSignal: controller.signal,
      });
      response.data.data && setData(response.data.list);
      setIsDataFetched(true);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchOperators = async () => {
    try {
      const response = await axiosPrivate.get("/operators/list", {
        AbortSignal: controller.signal,
      });
      response.data.list && setOperators(response.data.list);
    } catch (err) {
      console.log(err);
    }
  };

  const updateData = (operatorId, dataType, value) => {
    var payload = {};
    var temp = [];
    var flag = false;

    switch (dataType) {
      case "marginType":
        payload["marginType"] = value;
        break;
      case "margin":
        payload["margin"] = value;
        break;
      case "surchargeType":
        payload["surchargeType"] = value;
        break;
      case "surcharge":
        payload["surcharge"] = value;
        break;
    }
    temp = data.map((item) => {
      if (item.operatorId != operatorId) return item;
      flag = true;
      return { ...item, ...payload };
    });
    if (!flag) {
      temp.push({ operatorId, ...payload });
    }
    setData(temp);
  };

  const submitData = async () => {
    console.log(data);
    try {
      const response = await axiosPrivate.post("/margin/inward/update", data);
      response.data.success &&
        swal("Sucess!", "Inward Margin Updated Successfully!", "success");
    } catch (err) {
      swal("Error!", "Something went Wrong!", "error");
    }
  };

  useEffect(() => {
    Promise.all([fetchAPIList(), fetchOperators()]);
    return () => {
      controller.abort();
    };
  }, [serviceType]);

  useEffect(() => {
    if (data.length || !operators.length) {
      return;
    }
    setData(
      operators.map((item) => {
        return {
          operatorId: item.id,
          marginType: "Percentage",
          margin: 0,
          surchargeType: "Percentage",
          surcharge: 0,
        };
      })
    );
  }, [operators, data]);

  const columns = [
    {
      text: "Operator Name",
      dataField: "operatorName",
      width: "20%",
    },
    {
      text: "Margin",
      dataField: "margin",
      isDummyField: true,
      style: { width: "40%" },
      formatExtraData: [data, updateData],
      formatter: (c, row, i, extraData) => {
        return (
          <>
            <Row>
              <Col md={4}>
                <select
                  style={{ width: "120px", height: "40px" }}
                  className="form-control"
                  value={
                    data?.find((item) => item.operatorId == row.id)
                      ?.marginType || "Percentage"
                  }
                  onChange={(e) =>
                    updateData(row.id, "marginType", e.target.value)
                  }
                >
                  <option key="0" value="Percentage">
                    Percentage
                  </option>
                  <option key="1" value="Amount">
                    Amount
                  </option>
                </select>
              </Col>
              <Col md={4}>
                <Input
                  type="text"
                  style={{ width: "70px", height: "40px" }}
                  placeholder="0"
                  onChange={(e) => updateData(row.id, "margin", e.target.value)}
                  value={
                    data?.find((item) => item.operatorId == row.id)?.margin ||
                    "0"
                  }
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      text: "Surcharge",
      dataField: "surcharge",
      isDummyField: true,
      formatExtraData: [data, updateData],
      style: { width: "40%" },
      formatter: (c, row, i, extraData) => {
        return (
          <>
            <Row>
              <Col md={4}>
                <select
                  style={{ width: "120px", height: "40px" }}
                  className="form-control"
                  value={
                    extraData[0]?.find((item) => item.operatorId == row.id)
                      ?.surchargeType || "Percentage"
                  }
                  onChange={(e) =>
                    extraData[1](row.id, "surchargeType", e.target.value)
                  }
                >
                  <option key="0" value="Percentage">
                    Percentage
                  </option>
                  <option key="1" value="Amount">
                    Amount
                  </option>
                </select>
              </Col>
              <Col md={2}>
                <Input
                  type="text"
                  style={{ width: "70px", height: "40px" }}
                  placeholder="0"
                  onChange={(e) =>
                    extraData[1](row.id, "surcharge", e.target.value)
                  }
                  value={
                    extraData[0]?.find((item) => item.operatorId == row.id)
                      ?.surcharge || "0"
                  }
                />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Apply Inward Margin</h3>
          </CardHeader>

          <ToolkitProvider keyField="id" data={operators} columns={columns}>
            {(props) => (
              <>
                <Col className="text-center" xs="20">
                  <div
                    id="datatable-basic_filter"
                    className="dataTables_filter px-4 pb-1 float-right"
                  >
                    <Form inline className="m-2">
                      <select
                        className="form-control"
                        id="input-service"
                        name="service"
                        value={serviceType}
                        onChange={(e) => setServiceType(e.target.value)}
                      >
                        {Object.keys(operatorTypes).map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {operatorTypes[item]}
                            </option>
                          );
                        })}
                      </select>
                    </Form>
                  </div>
                </Col>
                <BootstrapTable
                  // wrapperClasses="table-responsive"
                  rowClasses="text-nowrap"
                  {...props.baseProps}
                  noDataIndication={
                    isDataFetched ? (
                      "Table is Empty"
                    ) : (
                      <Spinner animation="border" variant="primary" />
                    )
                  }
                  headerWrapperClasses="thead-light"
                  bodyClasses="align-items-center"
                  hover
                  bordered={false}
                  // condensed
                  bootstrap4
                />
              </>
            )}
          </ToolkitProvider>
          <div className="text-center">
            <Button
              size="md"
              color="primary"
              style={{ width: "100px" }}
              onClick={submitData}
            >
              Update
            </Button>
          </div>
          <br />
        </Card>
      </Container >
    </>
  );
};

export default InwardMarginTable;
