import useAxiosPrivate from "api/useAxiosPrivate";
import { useEffect } from "react";
import { createContext, useState } from "react";

const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
  const axiosPrivate = useAxiosPrivate();
  const [tenant, setTenant] = useState({
    tenantId: localStorage.getItem("tenantId"),
  });
  const [auth, setAuth] = useState(
    localStorage.getItem("accessToken")
      ? {
          accessToken: localStorage.getItem("accessToken"),
          refreshToken: localStorage.getItem("refreshToken"),
          roleId: localStorage.getItem("roleId"),
          userId: localStorage.getItem("userId"),
          username: localStorage.getItem("username"),
        }
      : {}
  );
  const [user, setUser] = useState({});
  const [balance, setBalance] = useState(null);
  const fetchBalance = async () => {
    try {
      const response = await axiosPrivate.get("/identity");
      setUser(response?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchTenant = async () => {
    try {
      const response = await axiosPrivate.get(
        "/domain-mapper/" + window.location.hostname
      );
      if (response.status === 200) {
        setTenant(response.data.data);
        localStorage.setItem("tenantId", response.data.data.tenantId);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (!auth?.userId) {
      return;
    }
    fetchBalance(auth);
  }, [auth]);
  // useEffect(() => {
  //   if (!auth || !auth.userId) return;
  //   let isMounted = true;
  //   const controller = new AbortController();
  //   const fetchBalance = async () => {
  //     console.log(`auth ${JSON.stringify(auth)}`);
  //     try {
  //       console.log(`userId ${auth.userId}`);
  //       const response = await axiosPrivate.get("/UserAPI/GetUser", {
  //         AbortSignal: controller.signal,
  //         params: {
  //           UserID: parseInt(auth.userId),
  //         },
  //       });
  //       isMounted && console.log(response?.data);
  //       isMounted && setBalance(response.data.Data[0].balance);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchBalance();
  //   return () => {
  //     isMounted = false;
  //     controller.abort();
  //   };
  // }, [auth]);
  const [persist, setPersist] = useState(
    JSON.parse(localStorage.getItem("persist")) || false
  );

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        persist,
        setPersist,
        balance,
        fetchBalance,
        tenant,
        fetchTenant,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
