import Index from "components/Dashboards/Index.js";
import Profile from "views/examples/Profile.js";
// import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import UserListTable from "components/Tables/UserListTable";
import TenantTable from "components/Tables/TenantTable";
import OperatorsTable from "components/Tables/OperatorsTable";
import PackageTable from "components/Tables/PackageTable";
import Prepaid from "components/Retailers/services/PrepaidService";
import RechargeAPITable from "components/Tables/RechargeAPITable";
import AddRechargeAPI from "components/Pages/AddRechargeAPI";
import InwardMarginTable from "components/Tables/InwardMarginTable";
import BroadbandService from "components/Retailers/services/BroadbandService";
import RoutingTable from "components/Tables/RoutingTable";
import TransactionLogsTable from "components/Tables/TransactionLogsTable";
import LiveRechargeLogsTable from "components/Tables/LiveRechargeLogsTable";
import ServiceRoutingTable from "components/Tables/ServiceRoutingTable";
import PendingRechargeReportTable from "components/Tables/PendingRechargeReportTable";
import OperatorFetchTable from "components/Tables/OperatorFetchTable";
import ConfigurationOperatorFetch from "components/Tables/ConfigurationOperatorFetch";
import Register from "views/examples/Register";
import SignupRequestTable from "components/Tables/SignupRequestTable";
import BankDetailsTable from "components/Tables/BankDetailsTable";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    allowedRoles: ["super-admin", "admin", "retailer"],
  },
  {
    name: "Tenant Management",
    icon: "fa fa-id-card text-red",
    layout: "/admin",
    allowedRoles: ["super-admin"],
    subCategories: [
      {
        path: "/tenant",
        name: "Tenant List",
        icon: "ni ni-bullet-list-67 text-red",
        component: TenantTable,
        layout: "/admin",
        allowedRoles: ["super-admin"],
      },
    ],
  },
  {
    name: "User Management",
    icon: "fa fa-id-card text-red",
    layout: "/admin",
    allowedRoles: ["super-admin", "admin"],
    subCategories: [
      {
        path: "/userlist",
        name: "User List",
        icon: "ni ni-bullet-list-67 text-red",
        component: UserListTable,
        layout: "/admin",
        allowedRoles: ["super-admin", "admin"],
      },
      {
        path: "/signup-requests",
        name: "Signup Requests",
        icon: "ni ni-bullet-list-67 text-red",
        component: SignupRequestTable,
        layout: "/admin",
        allowedRoles: ["admin"],
      },
      {
        path: "/adduser",
        name: "Add User",
        icon: "ni ni-bullet-list-67 text-red",
        component: Profile,
        layout: "/admin",
        allowedRoles: ["super-admin", "admin"],
      },
    ],
  },
  {
    name: "Package Management",
    icon: "fa fa-id-card text-red",
    layout: "/admin",
    allowedRoles: ["super-admin", "admin"],
    subCategories: [
      {
        path: "/list-packages",
        name: "List Packages",
        icon: "ni ni-bullet-list-67 text-red",
        component: PackageTable,
        layout: "/admin",
        allowedRoles: ["super-admin", "admin"],
      },
    ],
  },
  {
    name: "Operators",
    icon: "fa fa-id-card text-red",
    layout: "/admin",
    allowedRoles: ["super-admin", "admin"],
    subCategories: [
      {
        path: "/list-operators",
        name: "List Operators",
        icon: "ni ni-bullet-list-67 text-red",
        component: OperatorsTable,
        layout: "/admin",
        allowedRoles: ["super-admin", "admin"],
      },
      {
        path: "/inward-margin",
        name: "Inward Margin",
        icon: "ni ni-bullet-list-67 text-red",
        component: InwardMarginTable,
        layout: "/admin",
        allowedRoles: ["super-admin", "admin"],
      },
    ],
  },
  {
    name: "API",
    icon: "fa fa-id-card text-red",
    layout: "/admin",
    allowedRoles: ["admin", "super-admin"],
    subCategories: [
      {
        path: "/add-recharge-api",
        name: "Add Recharge API",
        icon: "ni ni-bullet-list-67 text-red",
        component: AddRechargeAPI,
        layout: "/admin",
        allowedRoles: ["admin", "super-admin"],
      },
      {
        path: "/api/service-api",
        name: "API List",
        icon: "ni ni-bullet-list-67 text-red",
        component: RechargeAPITable,
        layout: "/admin",
        allowedRoles: ["admin", "super-admin"],
      },
    ],
  },
  {
    name: "Routings",
    icon: "fa fa-id-card text-red",
    layout: "/admin",
    allowedRoles: ["admin", "super-admin"],
    subCategories: [
      {
        path: "/routing/general",
        name: "API Routing",
        icon: "ni ni-bullet-list-67 text-red",
        component: RoutingTable,
        layout: "/admin",
        allowedRoles: ["admin", "super-admin"],
      },
      {
        path: "/routing/service-routing",
        name: "Service Routing",
        icon: "ni ni-bullet-list-67 text-red",
        component: ServiceRoutingTable,
        layout: "/admin",
        allowedRoles: ["admin", "super-admin"],
      },
    ],
  },
  {
    name: "Services",
    icon: "fa fa-id-card text-red",
    layout: "/admin",
    allowedRoles: ["retailer"],
    subCategories: [
      {
        path: "/services/prepaid",
        name: "Prepaid",
        icon: "ni ni-bullet-list-67 text-red",
        component: Prepaid,
        layout: "/admin",
        isPackageDependent: true,
        allowedRoles: ["retailer"],
      },
      {
        path: "/services/broadband",
        name: "Broadband",
        icon: "ni ni-bullet-list-67 text-red",
        component: BroadbandService,
        layout: "/admin",
        isPackageDependent: true,
        allowedRoles: ["retailer"],
      },
    ],
  },
  {
    name: "Reports",
    icon: "fa fa-id-card text-red",
    layout: "/admin",
    allowedRoles: [
      "super-admin",
      "admin",
      "distributor",
      "master-distributor",
      "retailer",
    ],
    subCategories: [
      {
        path: "/reports/live-transactions",
        name: "Live Transactions",
        icon: "ni ni-bullet-list-67 text-red",
        component: LiveRechargeLogsTable,
        layout: "/admin",
        isSidebar: false,
        allowedRoles: [
          "super-admin",
          "admin",
          "distributor",
          "master-distributor",
          "retailer",
        ],
      },
      {
        path: "/reports/recharge-logs",
        name: "Recharge Logs",
        icon: "ni ni-bullet-list-67 text-red",
        component: TransactionLogsTable,
        layout: "/admin",
        isSidebar: false,
        allowedRoles: [
          "super-admin",
          "admin",
          "distributor",
          "master-distributor",
          "retailer",
        ],
      },
      {
        path: "/reports/pending-recharges",
        name: "Pending Recharge Logs",
        icon: "ni ni-bullet-list-67 text-red",
        component: PendingRechargeReportTable,
        layout: "/admin",
        isSidebar: false,
        allowedRoles: ["super-admin", "admin"],
      },
    ],
  },
  {
    name: "Payments",
    icon: "fa fa-id-card text-red",
    layout: "/admin",
    allowedRoles: ["super-admin", "admin"],
    subCategories: [
      {
        path: "/payments/bank-details",
        name: "Bank Details",
        icon: "ni ni-bullet-list-67 text-red",
        component: BankDetailsTable,
        layout: "/admin",
        allowedRoles: ["super-admin", "admin"],
      },
    ],
  },
  {
    name: "Extras",
    icon: "fa fa-id-card text-red",
    layout: "/admin",
    allowedRoles: ["super-admin"],
    subCategories: [
      {
        path: "/extras/operator-fetch-api",
        name: "Operator Fetch API",
        icon: "ni ni-bullet-list-67 text-red",
        component: OperatorFetchTable,
        layout: "/admin",
        allowedRoles: ["super-admin"],
      },
      {
        path: "/extras/configure-operator-fetch",
        name: "Configure Operator Fetch",
        icon: "ni ni-bullet-list-67 text-red",
        component: ConfigurationOperatorFetch,
        layout: "/admin",
        allowedRoles: ["super-admin", "admin"],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
];
export default routes;
