import { Button, Form, FormGroup, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
const { SearchBar } = Search;

const pagination = paginationFactory({
  pageStartIndex: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  paginationTotalRenderer: (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  ),
  sizePerPageList: [
    { text: "5", value: 5 },
    {
      text: "10",
      value: 10,
    },
  ],
});

const Table = ({
  data = [],
  columns = [
    {
      text: "Dummy",
      dataField: "dummy",
    },
  ],
  modal = {
    active: false,
  },
  paginate = false,
  isSearch = false,
}) => {
  return (
    <ToolkitProvider
      keyField="id"
      data={data}
      columns={columns}
      search={isSearch}
    >
      {(props) => (
        <>
          <Col className="text-center" xs="20">
            <div
              id="datatable-basic_filter"
              className="dataTables_filter px-4 pb-1 float-right"
            >
              <Form inline className="m-2">
                {isSearch && (
                  <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <label htmlFor="input-search" className="mr-sm-2">
                      Search:
                    </label>
                    <SearchBar
                      className="form-control-sm"
                      placeholder=""
                      srText="Search:"
                      {...props.searchProps}
                    />
                  </FormGroup>
                )}
                {modal.active && (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={modal.toggle ? modal.toggle : () => {}}
                  >
                    New
                  </Button>
                )}
              </Form>
            </div>
          </Col>
          <BootstrapTable
            // wrapperClasses="table-responsive"
            rowClasses="text-nowrap"
            {...props.baseProps}
            noDataIndication="Table is Empty"
            headerWrapperClasses="thead-light"
            bodyClasses="align-items-center"
            pagination={paginate ? pagination : null}
            hover
            bordered={false}
            // condensed
            bootstrap4
            responsive
          />
        </>
      )}
    </ToolkitProvider>
  );
};

export default Table;
