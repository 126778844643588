import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Col,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
  DropdownItem,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "api/useAxiosPrivate";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddCallbackModal from "components/Modals/AddCallbackModal";
import AddOperatorCodeModal from "components/Modals/AddOperatorCodeModal";

const RechargeAPISelectMenu = ({
  row,
  rechargeAPIData,
  selectedAPIName,
  operators,
  fetchData,
  operatorCodes,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCallbackModal, setIsCallbackModal] = useState(false);
  const [isOperatorCodeModal, setIsOperatorCodeModal] = useState(false);
  const history = useHistory();

  const toggleEditModal = () => {
    history.push("/admin/add-recharge-api", row);
  };
  const toggleCallbackModal = () => {
    setIsCallbackModal(!isCallbackModal);
  };
  const deleteServiceAPI = async () => {};
  const setOperatorCode = async () => {
    setIsOperatorCodeModal(!isOperatorCodeModal);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <ButtonDropdown
        isOpen={isOpen}
        direction="up"
        toggle={toggle}
        size="sm"
        color="primary"
      >
        <DropdownToggle caret color="primary"></DropdownToggle>
        <DropdownMenu>
          <DropdownItem header style={{ color: "#212529" }}>
            Action Menu
          </DropdownItem>
          <DropdownItem onClick={setOperatorCode}>Operator Code</DropdownItem>
          <DropdownItem onClick={toggleCallbackModal}>
            Configure Callback
          </DropdownItem>
          <DropdownItem onClick={toggleEditModal}>Edit</DropdownItem>
          <DropdownItem onClick={deleteServiceAPI}>Delete</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      {isOperatorCodeModal ? (
        <AddOperatorCodeModal
          toggle={setOperatorCode}
          show={isOperatorCodeModal}
          apiData={row}
          data={operatorCodes}
          operators={operators}
          fetchData={fetchData}
        />
      ) : null}
      {isCallbackModal ? (
        <AddCallbackModal
          toggle={toggleCallbackModal}
          show={isCallbackModal}
          data={row?.callback?.length ? row.callback[0] : undefined}
          apiData={row}
        />
      ) : null}
    </>
  );
};

const RechargeAPITable = () => {
  const history = useHistory();
  const toggle = () => {
    history.push("/admin/add-recharge-api");
  };
  const [data, setData] = useState([]);
  const [operatorCodes, setOperatorCodes] = useState([]);
  const [operators, setOperators] = useState([]);

  const { balance } = useAuth();
  const [isDataFetched, setIsDataFetched] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const fetchOperators = async () => {
    try {
      const response = await axiosPrivate.get("/operators/list", {
        params: { serviceType: "mobile" },
      });
      console.log(response.data);
      response.data.list && setOperators(response.data.list);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchOperatorCodes = async () => {
    try {
      const response = await axiosPrivate.get("/operator-code/list");
      console.log(response.data);
      response.data.list && setOperatorCodes(response.data.list);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAPIList = async (controller) => {
    try {
      const response = await axiosPrivate.get("/service-api/list", {
        params: { CategoryId: 1, callback: true },
      });
      console.log(response.data);
      response.data.list && setData(response.data.list);
      setIsDataFetched(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    Promise.all([
      fetchAPIList(controller),
      fetchOperators(),
      fetchOperatorCodes(),
    ]);
    return () => {
      controller.abort();
    };
  }, []);

  const pagination = paginationFactory({
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    ),
    sizePerPageList: [
      { text: "5", value: 5 },
      {
        text: "10",
        value: 10,
      },
    ],
  });

  const { SearchBar } = Search;

  const formatAction = (cell, row, i, extraData) => {
    return (
      <RechargeAPISelectMenu
        row={row}
        rechargeAPIData={data[0]}
        selectedAPIName={row.name}
        operators={extraData[1]}
        fetchData={extraData[2]}
        operatorCodes={extraData[3]}
      />
    );
  };

  const inSwitchUpdater = async (value) => {
    console.log("ran updater");
  };

  const columns = [
    {
      text: "#",
      isDummyField: true,
      dataField: "something",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "action",
      text: "Action",
      isDummyField: true,
      formatExtraData: [data, operators, fetchAPIList, operatorCodes],
      formatter: formatAction,
    },
    {
      text: "Name",
      dataField: "name",
    },
    {
      text: "API Short Name",
      dataField: "shortName",
    },
    {
      text: "Status",
      dataField: "isActive",
    },
  ];

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Recharge API</h3>
              </CardHeader>
              <ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Col className="text-center" xs="20">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 float-right"
                      >
                        <Form inline className="m-2">
                          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <label htmlFor="input-search" className="mr-sm-2">
                              Search:
                            </label>
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              srText="Search:"
                              {...props.searchProps}
                            />
                          </FormGroup>
                          <Button color="primary" size="sm" onClick={toggle}>
                            New
                          </Button>
                        </Form>
                      </div>
                    </Col>
                    <BootstrapTable
                      // wrapperClasses="table-responsive"
                      rowClasses="text-nowrap"
                      {...props.baseProps}
                      noDataIndication={
                        isDataFetched ? (
                          "Table is Empty"
                        ) : (
                          <Spinner animation="border" variant="primary" />
                        )
                      }
                      headerWrapperClasses="thead-light"
                      bodyClasses="align-items-center"
                      pagination={pagination}
                      hover
                      bordered={false}
                      // condensed
                      bootstrap4
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default RechargeAPITable;
