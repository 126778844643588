import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
  FormGroup,
  Input,
  CardBody,
  Table,
  Label,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "api/useAxiosPrivate";
import swal from "sweetalert";
import {
  useLocation,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";

const rechargeDynamicValues = [
  { label: "Static Value", value: "" },
  { label: "Our Transaction ID", value: "{{TID}}", testValue: "TEST123" },
  { label: "Circle ID", value: "{{CIRCLEID}}", testValue: "11" },
  { label: "Mobile Number", value: "{{MOBILE}}", testValue: "9826141207" },
  { label: "Recharge Amount", value: "{{RECHARGE_AMOUNT}}", testValue: "11" },
  { label: "Operator Code", value: "{{OPERATOR_CODE}}", testValue: "JIO" },
];

const AddRechargeAPI = () => {
  const data = useLocation().state;
  const [name, setName] = useState(data?.name || "");
  const [shortName, SetShortName] = useState(data?.shortName || "");
  const [serviceType, setServiceType] = useState(data?.serviceType || "mobile");
  const [firstLow, setFirstLow] = useState("");
  const [ipAddress, setIPAddress] = useState("");
  const [secondLow, setSecondLow] = useState("");
  const [thirdLow, setThirdLow] = useState("");
  const [balanceURL, setBalanceURL] = useState("");
  // const [balanceReqType, setBalanceReqType] = useState("get");
  const [rechargeFinalURL, setRechargeFinalURL] = useState("");
  const [rechargeURL, setRechargeURL] = useState(data?.apiUrl || "");
  const [rechargeReqType, setRechargeReqType] = useState(
    data?.methodType || "get"
  );
  //recharge response states
  const [rechargeResponseType, setRechargeResponseType] = useState("JSON");
  const [responseText, setResponseText] = useState(
    "Click on Fetch Response button to display response."
  );
  const axiosPrivate = useAxiosPrivate();
  const [responseJSON, setresponseJSON] = useState({});
  const [responseKeys, setResponseKeys] = useState(data?.responseKeys || []);
  const [keysValues, setKeysValues] = useState(
    data?.keysValues || [
      {
        key: "statusKey",
        value: "undefined",
      },
      {
        key: "supplierIdKey",
        value: "undefined",
      },
      {
        key: "operatorIdKey",
        value: "undefined",
      },
      {
        key: "balanceKey",
        value: "undefined",
      },
      {
        key: "successValue",
        value: "",
      },
      {
        key: "failedValue",
        value: "",
      },
    ]
  );
  const history = useHistory();
  const [params, setParams] = useState([
    {
      key: "",
      value: "",
      dynamic: false,
    },
    {
      key: "",
      value: "",
      dynamic: false,
    },
    {
      key: "",
      value: "",
      dynamic: false,
    },
    {
      key: "",
      value: "",
      dynamic: false,
    },
    {
      key: "",
      value: "",
      dynamic: false,
    },
    {
      key: "",
      value: "",
      dynamic: false,
    },
    {
      key: "",
      value: "",
      dynamic: false,
    },
  ]);

  const fetchIPAddress = async () => {
    try {
      const response = await axiosPrivate.get("/utility/fetch-ip");
      response.status === 200 &&
        response.data.success &&
        setIPAddress(response.data.ipAddress);
    } catch (err) {
      swal("error", err.message);
    }
  };

  const handleParamChange = (name, index, valueType = "key") => {
    const temp = [...params];
    if (valueType === "key") {
      temp[index].key = name;
    } else {
      temp[index].value = name;
    }
    if (valueType === "dynamic" && name === "") {
      temp[index].dynamic = false;
    } else if (valueType === "dynamic" && name !== "") {
      temp[index].dynamic = true;
    }
    setParams(temp);
  };

  const handleKeyValues = (name, value) => {
    let temp = [...keysValues];
    temp = temp.map((item) => {
      if (item.key === name) {
        item.value = value;
      }
      return item;
    });
    setKeysValues(temp);
  };

  const fetchResponse = async (e) => {
    if (isSubmit) return;
    setIsSubmit(true);
    try {
      var url = rechargeFinalURL;
      rechargeDynamicValues.forEach((item) => {
        if (item.value === "") {
          return;
        }
        url = url.replace(item.value, item.testValue);
      });
      console.log(rechargeFinalURL, url);
      const response = await axiosPrivate.post("/service-api/test-response", {
        requestType: rechargeReqType,
        url: url,
      });
      if (response.data && response.data.success) {
        setResponseText(JSON.stringify(response.data.body));
        setresponseJSON(response.data.body);
        setResponseKeys(Object.keys(response.data.body));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };

  useEffect(() => {
    fetchIPAddress();
  }, []);

  useEffect(() => {
    var queryParam = "?";
    for (const param of params) {
      if (!param.key || !param.value) continue;
      if (queryParam !== "?") {
        queryParam += `&`;
      }
      queryParam += `${param.key}=${param.value}`;
    }
    setRechargeFinalURL(rechargeURL + queryParam);
  }, [params, rechargeURL]);

  useEffect(() => {
    if (rechargeURL.includes("?") && rechargeURL.split("?").length > 1) {
      var queryParamsString = rechargeURL.split("?")[1];
      var tempParams = [...params];
      queryParamsString.split("&").forEach((item, index) => {
        item = item.split("=");
        tempParams[index].key = item[0];
        tempParams[index].value = item[1];
        tempParams[index].dynamic = false;
      });
      setRechargeURL(rechargeURL.split("?")[0]);
      setParams(tempParams);
      setRechargeFinalURL(rechargeURL);
    }
  }, [rechargeURL]);

  const { balance } = useAuth();
  const [isSubmit, setIsSubmit] = useState(false);
  const paramsKeys = [1, 2, 3, 4, 5, 6, 7];

  useEffect(() => {
    if (!data) return;
    //setup params
    setParams(
      paramsKeys.map((key, index) => {
        if (key <= data.customParams.length) {
          return data.customParams[index];
        }
        return {
          key: "",
          value: "",
          dynamic: false,
        };
      })
    );
  }, [data]);

  const saveAPI = async () => {
    if (isSubmit) return;
    setIsSubmit(true);
    try {
      const payload = {
        name: name,
        shortName: shortName,
        postType: "x-www-form-urlencoded",
        responseType: "JSON",
        methodType: rechargeReqType,
        apiUrl: rechargeURL,
        serviceType: "mobile",
        customParams: params,
        keysValues: keysValues,
        responseKeys: responseKeys,
      };
      // console.log(payload);
      // return setIsSubmit(false);
      const response = await axiosPrivate.post(
        data ? "/service-api/" + data?.id : "/service-api/add",
        JSON.stringify(payload)
      );
      if (
        response?.status >= 200 &&
        response?.status < 300 &&
        response?.data.success === true
      ) {
        swal(
          `Recharge API ${data ? "Updated" : "Added"} Sucessfully!`,
          "",
          "success"
        );
        history.push("/admin/api/service-api");
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err?.response ? err.response : err.data);
      swal("Something went wrong!", "", "error");
    }
  };

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Add Recharge API</h3>
              </CardHeader>
              <CardBody>
                {/* minimal setup card */}
                <Card className="shadow">
                  <CardHeader>API's Basic Details</CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label htmlFor="name">API Name*</label>
                          <Input
                            type="text"
                            style={{ height: "30px" }}
                            name="name"
                            id="name"
                            placeholder="API Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label htmlFor="name">API Short Name*</label>
                          <Input
                            style={{ height: "30px" }}
                            type="text"
                            name="short-name"
                            id="short-name"
                            placeholder="API Short Name"
                            value={shortName}
                            onChange={(e) => SetShortName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label htmlFor="name">IP Address</label>
                          <Input
                            style={{ height: "30px" }}
                            type="text"
                            name="ip-address"
                            id="ip-address"
                            placeholder="IP Address"
                            disabled={true}
                            value={ipAddress}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label htmlFor="name">Service Type</label>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={serviceType === "mobile"}
                              />{" "}
                              Mobile
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={serviceType === "dth"}
                              />{" "}
                              DTH
                            </Label>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <br />
                {/* configure recharge api */}
                <Card className="shadow">
                  <CardHeader>Configure Recharge API</CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <Card>
                          <CardHeader>Request Configuration</CardHeader>
                          <CardBody>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <label htmlFor="recharge-api">
                                    Recharge URL
                                  </label>
                                  <Input
                                    type="text"
                                    name="recharge-api"
                                    id="recharge-api"
                                    placeholder="Recharge API URL"
                                    value={rechargeURL}
                                    onChange={(e) =>
                                      setRechargeURL(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <label htmlFor="bal-req-type">
                                    Request Type
                                  </label>
                                  <Input
                                    type="select"
                                    name="bal-req-type"
                                    id="bal-req-type"
                                    value={rechargeReqType}
                                    onChange={(e) =>
                                      setRechargeReqType(e.target.value)
                                    }
                                  >
                                    <option value="get">GET</option>
                                    <option value="post">POST</option>
                                  </Input>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label>Parameter List</label>
                                <Table bordered>
                                  <thead>
                                    <tr>
                                      <th>Param Name</th>
                                      <th>Dynamic Value</th>
                                      <th>Static Value</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {paramsKeys.map((item, index) => {
                                      return (
                                        <tr key={item}>
                                          <td
                                            style={{
                                              padding: "10px",
                                              height: "30px",
                                            }}
                                          >
                                            <Input
                                              type="text"
                                              style={{ height: "30px" }}
                                              name={`param${item}`}
                                              id={`param${item}`}
                                              placeholder="Key"
                                              value={params[index].key}
                                              onChange={(e) =>
                                                handleParamChange(
                                                  e.target.value,
                                                  index,
                                                  "key"
                                                )
                                              }
                                            />
                                          </td>
                                          <td
                                            style={{
                                              padding: "10px",
                                              paddingBottom: "10px",
                                              height: "40px",
                                            }}
                                          >
                                            <select
                                              className="form-control-sm form-control"
                                              onChange={(e) =>
                                                handleParamChange(
                                                  e.target.value,
                                                  index,
                                                  "dynamic"
                                                )
                                              }
                                              value={
                                                data && params[index].dynamic
                                                  ? params[index].value
                                                  : paramsKeys[0].value
                                              }
                                            >
                                              {rechargeDynamicValues.map(
                                                (item, index) => {
                                                  return (
                                                    <option
                                                      key={index}
                                                      value={item.value}
                                                    >
                                                      {item.label}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                          </td>
                                          <td
                                            style={{
                                              padding: "10px",
                                              paddingBottom: "10px",
                                              height: "40px",
                                            }}
                                          >
                                            <Input
                                              style={{ height: "30px" }}
                                              type="text"
                                              disabled={params[index].dynamic}
                                              name={`value${item}`}
                                              id={`value${item}`}
                                              placeholder="Value"
                                              value={
                                                !params[index].dynamic
                                                  ? params[index].value
                                                  : ""
                                              }
                                              onChange={(e) =>
                                                handleParamChange(
                                                  e.target.value,
                                                  index,
                                                  "value"
                                                )
                                              }
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <label htmlFor="recfinalurl">Final URL</label>
                                  <Row>
                                    <Card style={{ width: "480px" }}>
                                      <CardBody
                                        style={{
                                          fontSize: "14px",
                                          padding: "10px",
                                        }}
                                      >
                                        <Col>
                                          <span>{rechargeFinalURL}</span>
                                        </Col>
                                      </CardBody>
                                    </Card>
                                  </Row>
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardHeader>Response Configuration</CardHeader>
                          <CardBody>
                            <Row>
                              <FormGroup>
                                <label>Response Type</label>
                                <Input
                                  style={{ height: "40px" }}
                                  type="select"
                                  name="response-type"
                                  id="response-type"
                                  value={rechargeResponseType}
                                  onChange={(e) =>
                                    setRechargeResponseType(e.target.value)
                                  }
                                >
                                  <option key="0" value="JSON">
                                    JSON
                                  </option>
                                  <option key="1" value={"XML"}>
                                    XML
                                  </option>
                                </Input>
                              </FormGroup>
                              <FormGroup>
                                <label className="control-label">&nbsp;</label>
                                <div style={{ marginLeft: "5px" }}>
                                  <Button
                                    type="button"
                                    size="md"
                                    color="primary"
                                    style={{ height: "40px" }}
                                    onClick={fetchResponse}
                                  >
                                    Fetch Response
                                  </Button>
                                </div>
                              </FormGroup>
                            </Row>
                            <label>Response Received</label>
                            <Row>
                              <Card style={{ width: "500px" }}>
                                <CardBody
                                  style={{
                                    fontSize: "14px",
                                    padding: "10px",
                                  }}
                                >
                                  <Col>
                                    <span>{responseText}</span>
                                  </Col>
                                </CardBody>
                              </Card>
                            </Row>

                            <Row>
                              <label>Keys and Values</label>
                              <Card style={{ width: "97%" }}>
                                <CardBody>
                                  <Row>
                                    <Col md={4}>
                                      <FormGroup>
                                        <label htmlFor="status-key">
                                          Status Key
                                        </label>
                                        <Input
                                          style={{ height: "40px" }}
                                          type="select"
                                          id="status-key"
                                          name="status-key"
                                          value={
                                            keysValues.find(
                                              (item) => item.key === "statusKey"
                                            ).value
                                          }
                                          onChange={(e) => {
                                            handleKeyValues(
                                              "statusKey",
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <option
                                            key={"-1"}
                                            value={"undefined"}
                                          >
                                            undefined
                                          </option>
                                          {responseKeys.map((item, index) => {
                                            return (
                                              <option key={index} value={item}>
                                                {item}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                      <FormGroup>
                                        <label htmlFor="success-value">
                                          Success Value
                                        </label>
                                        <Input
                                          type="text"
                                          id="success-value"
                                          style={{ height: "40px" }}
                                          name="success-value"
                                          placeholder="Success Status Value"
                                          value={
                                            keysValues.find(
                                              (item) =>
                                                item.key === "successValue"
                                            ).value
                                          }
                                          onChange={(e) => {
                                            handleKeyValues(
                                              "successValue",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                      <FormGroup>
                                        <label htmlFor="success-value">
                                          Failed Values
                                        </label>
                                        <Input
                                          type="text"
                                          id="failed-value"
                                          style={{ height: "40px" }}
                                          name="failed-value"
                                          placeholder="Failed Status Values"
                                          value={
                                            keysValues.find(
                                              (item) =>
                                                item.key === "failedValue"
                                            ).value
                                          }
                                          onChange={(e) => {
                                            handleKeyValues(
                                              "failedValue",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                              <Card style={{ width: "97%" }}>
                                <CardBody>
                                  <Row>
                                    <Col md={4}>
                                      <FormGroup>
                                        <label htmlFor="supplier-key">
                                          Supplier Key
                                        </label>
                                        <Input
                                          style={{ height: "40px" }}
                                          type="select"
                                          id="supplier-key"
                                          name="supplier-key"
                                          defaultValue={
                                            keysValues.find(
                                              (item) =>
                                                item.key === "supplierIdKey"
                                            ).value
                                          }
                                          onChange={(e) => {
                                            handleKeyValues(
                                              "supplierIdKey",
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <option
                                            key={"-1"}
                                            value={"undefined"}
                                          >
                                            undefined
                                          </option>
                                          {responseKeys.map((item, index) => {
                                            return (
                                              <option key={index} value={item}>
                                                {item}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                      <FormGroup>
                                        <label htmlFor="supplier-key">
                                          Balance Key
                                        </label>
                                        <Input
                                          style={{ height: "40px" }}
                                          type="select"
                                          id="balance-key"
                                          name="balance-key"
                                          defaultValue={
                                            keysValues.find(
                                              (item) =>
                                                item.key === "balanceKey"
                                            ).value
                                          }
                                          onChange={(e) => {
                                            handleKeyValues(
                                              "balanceKey",
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <option
                                            key={"-1"}
                                            value={"undefined"}
                                          >
                                            undefined
                                          </option>
                                          {responseKeys.map((item, index) => {
                                            return (
                                              <option key={index} value={item}>
                                                {item}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                      <FormGroup>
                                        <label htmlFor="operator-key">
                                          Operator Key
                                        </label>
                                        <Input
                                          style={{ height: "40px" }}
                                          type="select"
                                          id="operator-key"
                                          name="operator-key"
                                          defaultValue={
                                            keysValues.find(
                                              (item) =>
                                                item.key === "operatorIdKey"
                                            ).value
                                          }
                                          onChange={(e) => {
                                            handleKeyValues(
                                              "operatorIdKey",
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <option
                                            key={"-1"}
                                            value={"undefined"}
                                          >
                                            undefined
                                          </option>
                                          {responseKeys.map((item, index) => {
                                            return (
                                              <option key={index} value={item}>
                                                {item}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <br />
                <Row>
                  <span style={{ float: "none", margin: "0 auto" }}>
                    <Button
                      color="primary"
                      type="button"
                      onClick={saveAPI}
                      disabled={isSubmit}
                    >
                      Save
                    </Button>
                  </span>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddRechargeAPI;
