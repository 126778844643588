import useAuth from "hooks/useAuth";
import AdminDashboard from "./AdminDashboard";

const Index = (props) => {
  const { auth } = useAuth();

  if (auth.role == "admin") {
    return <AdminDashboard />;
  } else {
    console.log("here");
    return <AdminDashboard />;
  }
};

export default Index;
