import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Row,
  Col,
  Progress,
  CardFooter,
} from "reactstrap";

const Tile = ({ title, value, color }) => {
  return (
    <>
      <div style={{ width: "15rem" }}>
        <Card className="card-stats mb-4 mb-lg-0">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-muted mb-0">
                  {title}
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">{value}</span>
              </div>
            </Row>
            <Row md={1}>
              <Progress
                max="100"
                value="100"
                style={{ height: "5px" }}
                color={color}
              />
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Tile;
