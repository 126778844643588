import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import UserHeader from "components/Headers/UserHeader.js";
import swal from "sweetalert";
import useAxiosPrivate from "api/useAxiosPrivate";
import { toast } from "react-toastify";
import { roles } from "config/constaints";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useAuth from "hooks/useAuth";

const Profile = () => {
  const { auth } = useAuth();
  const data = useLocation().state;
  const [role, setRole] = useState(
    data
      ? { value: data.role, label: roles[data.role] }
      : { label: "Retailer", value: "retailer" }
  );
  const [tenant, setTenant] = useState(data ? data.tenantId : "");
  const [name, setName] = useState(data ? data.name : "");
  const [emailId, setEmailId] = useState(data?.email ? data.email : "");
  const [address, setAddress] = useState(data?.address ? data.address : "");
  const [username, setUsername] = useState(data?.username ? data.username : "");
  const [isSubmit, setIsSubmit] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [tenants, setTenants] = useState([]);
  const [users, setUsers] = useState([]);
  const [referal, setReferal] = useState({});
  useEffect(() => {
    const controller = new AbortController();
    const fetchUsers = async () => {
      try {
        const response = await axiosPrivate.get("/identity/list");
        if (response.data && response.data.data.length) {
          let users = response.data.data.map((item) => {
            return {
              value: item.id,
              label: `${item.username} (${roles[item.role]})`,
            };
          });
          setUsers(users);
          setReferal(
            data && users.find((item) => item.value === data.parent)?.label
              ? {
                  label: users.find((item) => item.value === data.parent)
                    ?.label,
                }
              : ""
          );
        }
      } catch (err) {
        toast.error(err.message, {
          autoClose: 3000,
          hideProgressBar: false,
          pauseOnHover: true,
          position: "bottom-center",
        });
      }
    };
    const fetchTenants = async () => {
      try {
        const response = await axiosPrivate.get("/tenant/tenant-list");
        response.data.data && setTenants(response.data.data);
        if (response.data.data && response.data.data.length) {
          setTenants(
            response.data.data.map((item) => {
              return {
                label: item.tenantId,
                value: item.tenantId,
              };
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    };
    Promise.all([fetchUsers(), fetchTenants()]);
    return () => {
      controller.abort();
    };
  }, []);

  function generatePassword() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const submitForm = async (e) => {
    e.preventDefault();
    if (isSubmit) return;
    try {
      setIsSubmit(true);
      const payload = {
        referral: referal?.value ? referal.value : "",
        name: name,
        email: emailId,
        role: role.value ? role.value : "",
        address: address,
        username: username,
        password: data ? data.password : generatePassword(),
      };
      if (auth.roleId === "super-admin") payload.tenantId = tenant.value;

      const response = await axiosPrivate.post(
        data.isApproved = true ? `/identity/add` : data ? "/identity/${data.id}" : '/identity/add',
        payload
      );
      console.log(JSON.stringify(response?.data));
      if (response?.status >= 200 && response?.status < 300) {
        swal("User Added Sucessfully!", "", "success");
      }
    } catch (err) {
      console.log(err.response);
      swal("Something went wrong!", "", "error");
    } finally {
      setIsSubmit(false);
    }
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Create User</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      {auth.roleId === "super-admin" ? (
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-tenant"
                            >
                              Tenant
                            </label>
                            <Select
                              styles={{
                                height: 10,
                                width: 10,
                              }}
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable
                              value={tenant}
                              options={tenants}
                              onChange={(item) => {
                                setTenant(item);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <></>
                      )}
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-referal"
                          >
                            Parent
                          </label>
                          <Select
                            styles={{
                              height: 10,
                              width: 10,
                            }}
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable
                            value={referal}
                            options={users}
                            onChange={(item) => {
                              setReferal(item);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-role"
                          >
                            Choose Role
                          </label>
                          <Select
                            styles={{
                              height: 10,
                              width: 10,
                            }}
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable
                            value={role}
                            options={Object.keys(roles)
                              .filter((key) => {
                                if (key === "super-admin") {
                                  return false;
                                }
                                if (
                                  auth.roleId !== "super-admin" &&
                                  key === "admin"
                                ) {
                                  return false;
                                }
                                return true;
                              })
                              .map((key) => {
                                return { label: roles[key], value: key };
                              })}
                            onChange={(item) => {
                              setRole(item);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="Name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            User Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="username"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email ID
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Enter Email ID"
                            type="text"
                            value={emailId}
                            onChange={(e) => setEmailId(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-address"
                            placeholder="Enter Address"
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Col className="text-center" xs="12">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => submitForm(e)}
                      size="lg"
                    >
                      {data && data.isApproved ? "Add" :  data ? "Update" : "Add"} User
                    </Button>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
