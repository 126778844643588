import axios from "axios";

export const BASE_URL = "https://recharge-software.api.webspeed.online/api";
// export const BASE_URL = "http://localhost:5000/api";

axios.defaults.withCredentials = true;

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

const get = async (endpoint, authToken, params) => {
  return await axiosPrivate.get(endpoint, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params: params,
  });
};

const post = async (endpoint, authToken, body = {}, params) => {
  return await axiosPrivate.get(endpoint, body, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params: params,
  });
};
