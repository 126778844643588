import useAxiosPrivate from "api/useAxiosPrivate";
import { serviceTypes } from "config/constaints";
// import SlabMasterCommissionDetailTable from "components/Tables/SlabMasterCommissionDetailTable";
import useAuth from "hooks/useAuth";
import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Input, ModalFooter, Row, Col, Label } from "reactstrap";
import swal from "sweetalert";

const AssignPackageModal = ({ show, toggle, data,packages,fetchUsers }) => {
  const [packageId,setPackageId] = useState(data?.package.length ? data.package[0].packageId : "0");
  const [isSubmit, setIsSubmit] = useState(false);
  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });
  const { auth } = useAuth();


  const axiosPrivate = useAxiosPrivate();

  const submitForm = async (e) => {
    e.preventDefault();
    if (isSubmit) return;
    try {
      setIsSubmit(true);
      const payload = {packageId,userId:data.id};
      const response = await axiosPrivate.post(
        "/packages-assign",
        payload
      );
      console.log(JSON.stringify(response?.data));
      if (
        response?.status >= 200 &&
        response?.status < 300 &&
        response?.data?.success === true
      ) {
        swal("Package Assigned Sucessfully!", "", "success");
        toggle();
        fetchUsers()
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err?.response ? err.response : err.data);
      console.log(err)
      swal("Something went wrong!", "", "error");
    } finally {
      if (isMounted.current) {
        setIsSubmit(false);
      }
    }
  };
  return (
    <div>
      <Modal
        isOpen={show}
        backdrop="static"
        keyboard
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Assign Package ({data.username})</ModalHeader>
        <ModalBody>
        <FormGroup>
            <label htmlFor="input-package">Select Package</label>
            <select
              className="form-control"
              type=""
              value={packageId}
              onChange={(e) =>
                setPackageId(e.target.value)
              }
              id="input-package"
              name="package"
            >
              <option key={"0"} value={"0"}>Select Package</option>
              {console.log(packages)}
              {packages.map(item=><option key={item.id} value={item.id}>{item.name}</option>
              )}
            </select>
          </FormGroup>
        {
          packageId!=="0" ? <FormGroup>
            <label>Benefits</label>
            <Row md={3}>
              {serviceTypes.map(service =>
                <Col>
                  <Label check>
                    <Input type="checkbox" disabled checked={packages.find(item=>item.id===packageId).services.includes(service.toLowerCase())}/>{' '}
                    {service}
                  </Label>
                </Col>
              )}
            </Row>
          </FormGroup>:null
        }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => submitForm(e)} disabled={packageId==="0"}>
            Update
          </Button>{" "}
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AssignPackageModal;
