import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAxiosPrivate from "api/useAxiosPrivate";
import { Link } from "react-router-dom";
import { roles } from "config/constaints";
import useAuth from "hooks/useAuth";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AssignPackageModal from "components/Modals/AssignPackageModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "react-bootstrap/Spinner";

const Password = ({ text }) => {
  const [show, setShow] = useState(false);
  return (
    <Form inline>
      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
        <Input
          type="text"
          size={"sm"}
          width={"5"}
          disabled
          value={show ? text : "*".repeat(text.length)}
        />
        <Button
          type="button"
          size="sm"
          color="primary"
          onClick={() => setShow(!show)}
        >
          {show ? (
            <FontAwesomeIcon icon={faEyeSlash} />
          ) : (
            <FontAwesomeIcon icon={faEye} />
          )}
        </Button>
      </FormGroup>
    </Form>
  );
};

const UserSelectMenu = ({ row, fetchData, packages, auth }) => {
  const axiosPrivate = useAxiosPrivate();
  const [isOpen, setIsOpen] = useState(false);
  const [isAssignOpen, setAssignOpen] = useState(false);

  const history = useHistory();
  const toggleEditModal = () => {
    history.push("/admin/adduser", row);
  };
  const toggleAssignPackage = () => {
    setAssignOpen(!isAssignOpen);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const DeleteUser = async () => {
    try {
      await axiosPrivate.delete(`/identity/${row.id}`);
      swal("User Deleted Successfully!", "", "success");
      fetchData();
    } catch (err) {
      swal(err?.response?.data?.message || err.message, "", "error");
    }
  };

  const deactivateUser = async () => {
    try {
      await axiosPrivate.post(`/identity/${row.id}/deactivate`);
      swal("User Deactivated Successfully!", "", "success");
      fetchData();
    } catch (err) {
      swal(err?.response?.data?.message || err.message, "", "error");
    }
  };

  const activateUser = async () => {
    try {
      await axiosPrivate.post(`/identity/${row.id}/activate`);
      swal("User Activated Successfully!", "", "success");
      fetchData();
    } catch (err) {
      swal(err?.response?.data?.message || err.message, "", "error");
    }
  };

  return (
    <>
      <ButtonDropdown
        isOpen={isOpen}
        direction="up"
        toggle={toggle}
        size="sm"
        color="primary"
      >
        <DropdownToggle caret color="primary"></DropdownToggle>
        <DropdownMenu>
          <DropdownItem header style={{ color: "#212529" }}>
            Action Menu
          </DropdownItem>
          {row.role !== auth?.roleId ? (
            <DropdownItem onClick={toggleAssignPackage}>
              Assign Package
            </DropdownItem>
          ) : (
            ""
          )}
          <DropdownItem onClick={toggleEditModal}>Edit</DropdownItem>
          {row.isActive ? (
            <DropdownItem onClick={deactivateUser}>
              Deactivate User
            </DropdownItem>
          ) : (
            <DropdownItem onClick={activateUser}>Activate User</DropdownItem>
          )}

          <DropdownItem onClick={DeleteUser}>Delete</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      {isAssignOpen === true ? (
        <AssignPackageModal
          toggle={toggleAssignPackage}
          show={isAssignOpen}
          data={row}
          packages={packages}
          fetchUsers={fetchData}
        />
      ) : null}
    </>
  );
};

const UserListTable = () => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };
  const [data, setData] = useState([]);
  const [packages, setPackages] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { auth, tenant } = useAuth();
  const [isDataFetched, setIsDataFetched] = useState(false);

  const fetchAPIList = async () => {
    try {
      if (isDataFetched) {
        setIsDataFetched(false);
      }
      const response = await axiosPrivate.get("/identity/list");
      console.log(response.data);
      response.data.data && setData(response.data.data);
      setIsDataFetched(true);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPackages = async () => {
    try {
      const response = await axiosPrivate.get("/packages/list");
      console.log(response.data);
      response.data.data && setPackages(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    Promise.all([fetchPackages(), fetchAPIList()]);
    return () => {
      controller.abort();
    };
  }, []);

  const pagination = paginationFactory({
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    ),
    sizePerPageList: [
      { text: "5", value: 5 },
      {
        text: "10",
        value: 10,
      },
    ],
  });

  const { SearchBar } = Search;

  const formatAction = (cell, row, i, extraData) => {
    return (
      <UserSelectMenu
        row={row}
        fetchData={extraData[1]}
        packages={extraData[0]}
        auth={auth}
      />
    );
  };

  const columns = [
    {
      text: "#",
      isDummyField: true,
      dataField: "something",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "action",
      text: "Action",
      isDummyField: true,
      formatExtraData: [packages, fetchAPIList],
      formatter: formatAction,
    },
    {
      text: "Role",
      dataField: "role",
      formatter: (c) => {
        return roles[c];
      },
    },
    {
      text: "Username",
      dataField: "username",
    },
    {
      text: "parent",
      dataField: "parent",
      formatter: (parent) => {
        if (parent.length) {
          return (
            <>
              {parent[0].username}
              <br />
              <small>({parent[0].role})</small>
            </>
          );
        }
        return "N/A";
      },
    },
    {
      text: "email",
      dataField: "email",
    },
    {
      text: "password",
      dataField: "password",
      formatter: (c) => {
        return <Password text={c} />;
      },
    },
    {
      text: "Is Active",
      dataField: "isActive",
    },
  ];

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">User List</h3>
              </CardHeader>

              <ToolkitProvider
                keyField="name"
                data={data}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Col className="text-center" xs="20">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 float-right"
                      >
                        <Form inline>
                          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <label htmlFor="input-search" className="mr-sm-2">
                              Search:
                            </label>
                            {/* <MySearch {...props.searchProps} /> */}
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              srText="Search:"
                              {...props.searchProps}
                            />
                          </FormGroup>
                          <Link to="/admin/adduser">
                            <Button
                              color="primary"
                              size="sm"
                              // onClick={toggle}
                            >
                              New
                            </Button>
                          </Link>
                        </Form>
                      </div>
                    </Col>
                    <BootstrapTable
                      // wrapperClasses="table-responsive"
                      rowClasses="text-nowrap"
                      {...props.baseProps}
                      noDataIndication={
                        isDataFetched ? (
                          "Table is Empty"
                        ) : (
                          <Spinner animation="border" variant="primary" />
                        )
                      }
                      headerWrapperClasses="thead-light"
                      bodyClasses="align-items-center"
                      pagination={pagination}
                      hover
                      bordered={false}
                      // condensed
                      bootstrap4
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default UserListTable;
