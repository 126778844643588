import { BASE_URL } from "api/axios";
import useAxiosPrivate from "api/useAxiosPrivate";
import CustomToggleSwitch from "components/Elements/CustomToggleSwitch";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Form,
  Row,
  Col,
} from "reactstrap";
import swal from "sweetalert";

const AddCallbackModal = ({ show, toggle, data, apiData }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const isMounted = useRef(true);
  console.log(data);
  const [name, setName] = useState(data ? data.apiShortName : "");
  const [txnId, setTxnId] = useState(data ? data.txnId : "");
  const [ourTxnId, setOurTxnId] = useState(data ? data.ourTxnId : "");
  const [statusKey, setStatusKey] = useState(data ? data.statusKey : "");
  const [failureValue, setFailureValue] = useState(
    data ? data.failureValue.join(",") : ""
  );
  const [balanceKey, setBalanceKey] = useState(data ? data.balanceKey : "");
  const [successValue, setSuccessValue] = useState(
    data ? data.successValue : ""
  );
  const [pendingValue, setPendingValue] = useState(
    data ? data.pendingValue : ""
  );
  const [statusMsgKey, setStatusMsgKey] = useState(
    data ? data.statusMsgKey : ""
  );

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });
  const submitForm = async (e) => {
    e.preventDefault();
    if (isSubmit) return;
    if (
      !name ||
      !txnId ||
      !statusKey ||
      !successValue ||
      !pendingValue ||
      !failureValue
    ) {
      swal("Missing Required Fields!", "", "error");
      return;
    }
    try {
      setIsSubmit(true);
      const response = await axiosPrivate.post(
        data ? `/service-api/callback/${data.id}` : "/service-api/callback/add",
        {
          serviceApiId: apiData.id,
          apiShortName: name,
          ourTxnId: ourTxnId,
          supplierTxnId: txnId,
          statusKey: statusKey,
          successValue: successValue,
          failureValue: failureValue.split(","),
          balanceKey: balanceKey,
          pendingValue: pendingValue,
          messageKey: statusMsgKey,
        }
      );
      console.log(response?.data);
      if (response?.data.success === true) {
        swal("Callback Added Sucessfully!", "", "success");
        toggle();
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err?.response ? err.response : err);
      swal("Something went wrong!", "", "error");
    } finally {
      if (isMounted.current) {
        setIsSubmit(false);
      }
    }
  };
  return (
    <div>
      <Modal
        size="lg"
        isOpen={show}
        backdrop="static"
        keyboard
        toggle={toggle}
        style={{ width: "100%" }}
      >
        <ModalHeader toggle={toggle}>Add Callback</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    Callback Name
                  </label>
                  <Input
                    id="input-name"
                    name="name"
                    type="text"
                    placeholder="Callback Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="8">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-url">
                    Callback URL
                  </label>
                  <div>
                    <label className="mt-2 text-primary ">
                      {`${BASE_URL}/callback/handler/${name
                        .toLocaleLowerCase()
                        .replace(" ", "-")}`}
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-txn">
                    Our Txn ID Key
                  </label>
                  <Input
                    id="input-txn"
                    name="txn"
                    type="text"
                    placeholder="Our Txn ID Key"
                    value={ourTxnId}
                    onChange={(e) => setOurTxnId(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-op-txn">
                    Transaction ID Key
                  </label>
                  <Input
                    id="input-op-txn"
                    name="op-txn"
                    type="text"
                    placeholder="Transaction ID Key"
                    value={txnId}
                    onChange={(e) => setTxnId(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-status">
                    Status Key
                  </label>
                  <Input
                    id="input-status"
                    name="status"
                    type="text"
                    placeholder="Status Key"
                    value={statusKey}
                    onChange={(e) => setStatusKey(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-success-value"
                  >
                    Success Value
                  </label>
                  <Input
                    id="input-success-value"
                    name="success-value"
                    type="text"
                    placeholder="Success Value"
                    value={successValue}
                    onChange={(e) => setSuccessValue(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-failure">
                    Failure Value
                  </label>
                  <Input
                    id="input-failure"
                    name="failure"
                    type="text"
                    placeholder="Failure Value"
                    value={failureValue}
                    onChange={(e) => setFailureValue(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-pending">
                    Pending Value
                  </label>
                  <Input
                    id="input-pending"
                    name="pending"
                    type="text"
                    placeholder="Pending Value"
                    value={pendingValue}
                    onChange={(e) => setPendingValue(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-balance">
                    Balance Key
                  </label>
                  <Input
                    id="input-balance"
                    name="balance"
                    type="text"
                    placeholder="Balance Key"
                    value={balanceKey}
                    onChange={(e) => setBalanceKey(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-status-msg"
                  >
                    Message Key
                  </label>
                  <Input
                    id="input-status-msg"
                    name="status-msg"
                    type="text"
                    placeholder="Message Key"
                    value={statusMsgKey}
                    onChange={(e) => setStatusMsgKey(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => submitForm(e)}>
            Add
          </Button>{" "}
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddCallbackModal;
