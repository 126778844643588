import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  DropdownMenu,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAxiosPrivate from "api/useAxiosPrivate";
import AddRoutingModal from "components/Modals/AddRoutingModal";
import Spinner from "react-bootstrap/Spinner";
import swal from "sweetalert";

const RoutingSelectMenu = ({
  row,
  operators,
  users,
  circles,
  rechargeAPIs,
  fetchRoutings,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const toggleEditModal = () => setEditModal(!editModal);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const deleteRouting = async () => {
    let result = await swal({
      // title:"Are you sure?",
      title: "Do you want to delete routing?",
      text: "Press delete to continue.",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    });
    if (result === null) {
      return;
    }
    try {
      const response = await axiosPrivate.delete("/routings/" + row.id);
      if (response.status == 200) {
        swal("success", "Routing deleted successfully!", "success");
      } else if (response.data.message) {
        throw new Error(response.data.message);
      }
    } catch (err) {
      swal("error", err.message, "error");
    } finally {
      fetchRoutings();
    }
  };
  return (
    <>
      <ButtonDropdown
        isOpen={isOpen}
        direction="up"
        toggle={toggle}
        size="sm"
        color="primary"
      >
        <DropdownToggle caret color="primary"></DropdownToggle>
        <DropdownMenu>
          <DropdownItem header style={{ color: "#212529" }}>
            Action Menu
          </DropdownItem>
          <DropdownItem onClick={toggleEditModal}>Edit</DropdownItem>
          <DropdownItem onClick={deleteRouting}>Delete</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      {editModal === true ? (
        <AddRoutingModal
          toggle={toggleEditModal}
          show={editModal}
          data={row}
          operators={operators}
          users={users}
          circles={circles}
          rechargeAPIs={rechargeAPIs}
          loadData={fetchRoutings}
        />
      ) : null}
    </>
  );
};

const RoutingTable = () => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };
  const [data, setData] = useState([]);
  const [operators, setOperators] = useState([]);
  const [users, setUsers] = useState([]);
  const [circles, setCircles] = useState([]);
  const [apis, setApis] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const axios = useAxiosPrivate();

  const { SearchBar } = Search;

  const formatAction = (cell, row, c, extraData) => {
    return (
      <RoutingSelectMenu
        row={row}
        operators={extraData[0]}
        users={extraData[1]}
        circles={extraData[2]}
        rechargeAPIs={extraData[3]}
        fetchRoutings={extraData[4]}
      />
    );
  };

  const fetchRoutings = async () => {
    try {
      const response = await axios.get("/routings/list");
      const data = response?.data?.list;
      // if(data.response_code)
      data && setData(data);
      setIsDataFetched(true);
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      text: "action",
      dataField: "action",
      isDummyField: true,
      formatter: formatAction,
      formatExtraData: [operators, users, circles, apis, fetchRoutings],
    },
    {
      text: "Operator",
      dataField: "operatorId",
      formatExtraData: [operators],
      formatter: (cell, row, i, extraData) => {
        if (!cell) return "All";
        let data = extraData[0].filter((item) => {
          return item.id === row.operatorId;
        });
        return data[0]?.operatorName;
      },
    },
    {
      text: "circle",
      dataField: "circleId",
      formatExtraData: circles,
      formatter: (cell, row, i, extraData) => {
        if (!cell) return "All";
        let data = extraData.filter((item) => {
          return item.id === row.circleId;
        });
        return data[0]?.name;
      },
    },
    {
      text: "user",
      dataField: "userId",
      formatExtraData: users,
      formatter: (cell, row, i, extraData) => {
        if (!cell) return "All";
        let data = extraData.filter((item) => {
          return item.id === row.userId;
        });
        return data[0]?.username;
      },
    },
    {
      text: "amounts",
      dataField: "amounts",
      formatter: (cell) => {
        if (cell.length < 1) return "-";
        return cell.map((item) => `${item.min}-${item.max}`).join(",");
      },
    },
    {
      text: "primary API",
      dataField: "apis",
      formatExtraData: apis,
      formatter: (apis, row, ri, extraData) => {
        let data = extraData.filter((item) => {
          return item.id === apis.find((item) => item.apiType === 1).value;
        });
        return data[0]?.shortName;
      },
    },
    {
      text: "status",
      dataField: "isActive",
      formatter: (cell) => {
        return cell ? "Active" : "InActive";
      },
    },
  ];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchUsers = async () => {
      try {
        const response = await axios.get("/identity/list");

        const data = response?.data?.data;
        // if(data.response_code)
        isMounted && setUsers(data);
        isMounted && fetchRoutings();
      } catch (err) {
        console.error(err);
      }
    };
    const fetchOperators = async () => {
      try {
        const response = await axios.get("/operators/list");

        const data = response?.data?.list;
        // if(data.response_code)
        isMounted && setOperators(data);
        isMounted && fetchUsers();
      } catch (err) {
        console.error(err);
      }
    };
    const fetchCircles = async () => {
      try {
        const response = await axios.get("/states/list");
        const data = response?.data?.list;
        // if(data.response_code)
        isMounted && setCircles(data);
        isMounted && fetchOperators();
      } catch (err) {
        console.error(err);
      }
    };
    const fetchAPIList = async () => {
      try {
        const response = await axios.get("/service-api/list");

        const data = response?.data?.list;
        // if(data.response_code)
        isMounted && setApis(data);
        isMounted && fetchCircles();
      } catch (err) {
        console.error(err);
      }
    };
    fetchAPIList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">General Routing</h3>
              </CardHeader>

              <ToolkitProvider keyField="name" data={data} columns={columns}>
                {(props) => (
                  <>
                    <Col className="text-center" xs="20">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 float-right"
                      >
                        <Form inline>
                          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            {/* <label htmlFor="input-search" className="mr-sm-2">
                              Search:
                            </label> */}
                            {/* <MySearch {...props.searchProps} /> */}
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              srText="Search:"
                              {...props.searchProps}
                            />
                          </FormGroup>
                          <Button color="primary" size="sm" onClick={toggle}>
                            New
                          </Button>
                          {showModal ? (
                            <AddRoutingModal
                              show={showModal}
                              toggle={toggle}
                              operators={operators}
                              loadData={fetchRoutings}
                              users={users}
                              circles={circles}
                              rechargeAPIs={apis}
                            />
                          ) : null}
                        </Form>
                      </div>
                    </Col>
                    <BootstrapTable
                      // wrapperClasses="table-responsive"
                      rowClasses="text-nowrap"
                      {...props.baseProps}
                      noDataIndication={
                        isDataFetched ? (
                          "Table is Empty"
                        ) : (
                          <Spinner animation="border" variant="primary" />
                        )
                      }
                      headerWrapperClasses="thead-light"
                      bodyClasses="align-items-center"
                      hover
                      bordered={false}
                      // condensed
                      bootstrap4
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default RoutingTable;
