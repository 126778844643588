
export const serviceTypes = [
  "Mobile",
  "DTH",
  "Postpaid",
  "Landline",
  "Broadband",
  "Electricity",
  "LPG Booking",
  "Pipe Gas",
  "Cable TV",
  "Fast TAG",
  "Insuarance",
  "Water",
  "Finance",
];


export const colorStyles = ["primary", "success", "danger", "warning", "info", "light", "dark"]

export const endpoints = {
  ADD_OPERATOR: "/operators/add",
  LIST_OPERATOR: "/operators/list",
  GET_OPERATOR: "/operators/",
  DELETE_OPERATOR: "/operators"
}

export const roles = {
  "super-admin": "Master Admin",
  "admin": "Admin",
  "master-distributor": "Master Distributor",
  "distributor": "Distributor",
  "retailer": "Retailer",
  "api-user": "API User"
}