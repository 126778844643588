import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import useAxiosPrivate from "api/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import ServiceValidate from "components/Elements/ServiceValidate";

const BroadbandService = ({ isPackageDependent }) => {
  const [consumerNumber, setConsumerNumber] = useState("");
  const [operator, setOperator] = useState(0);
  const [billDetails, setBillDetails] = useState({});
  const [operators, setOperators] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const axios = useAxiosPrivate();
  const BILL_PAYMENT = "/UserAPI/BillPayment";

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchOperatorList = async () => {
      try {
        const response = await axios.post(BILL_PAYMENT, {
          type: "operatorlist",
        });

        console.log(response.data);
        isMounted &&
          setOperators(
            response.data.Data.data.filter((item) => {
              return item.category == "Broadband";
            })
          );
      } catch (err) {
        console.error(err);
      }
    };
    fetchOperatorList();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const fetchBill = async () => {
    if (isSubmit == true) return;
    setIsSubmit(true);
    try {
      const response = await axios.post(BILL_PAYMENT, {
        canumber: consumerNumber,
        operator: operator,
        type: "FetchBillDetails",
      });
      console.log(response.data);
      response.data?.Data.response_code && setBillDetails(response.data.Data);
    } catch (err) {
      console.log(err.response);
    } finally {
      setIsSubmit(false);
    }
  };

  const payBill = async () => {
    if (isSubmit == true) return;
    setIsSubmit(true);
    try {
      const response = await axios.post(BILL_PAYMENT, {
        canumber: consumerNumber,
        operator: operator,
        amount: billDetails.amount,
        Type: "paybill",
        latitude: "27.2232",
        longitude: "78.26535",
        referenceid: Math.floor(
          Math.random() * (9999999999 - 1000000000) + 1000000000
        ),
        bill_fetch: {
          billAmount: billDetails.amount,
          dueDate: billDetails.dueDate,
          userName: billDetails.name,
          acceptPayment: true,
          acceptPartPay: false,
          cellNumber: consumerNumber,
        },
      });
      console.log(response.data.Data);
      if (response.data.Data.response_code == 1) {
        swal(
          response.data.Data.message,
          `Reference Id: #${response.data.Data.ackno}`,
          "success"
        );
      } else {
        swal("Something went wrong!", "", "error");
      }
      // response.data?.response_code && setBillDetails(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };

  return (
    <>
      {isPackageDependent && <ServiceValidate serviceName="broadband" />}
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Broadband Bill</h3>
                <hr></hr>
              </CardHeader>
              <CardBody style={{ width: "50%" }}>
                <Form>
                  <FormGroup>
                    <Label for="input-operator" className="form-control-label">
                      Choose Operator
                    </Label>

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">☎️</InputGroupAddon>
                      <Input
                        className="form-control"
                        id="input-operator"
                        type="select"
                        value={operator}
                        onChange={(e) => setOperator(e.target.value)}
                      >
                        <option key={0}>Select Operator</option>
                        {operators.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="input-mobilenumber"
                      className="form-control-label"
                    >
                      Consumer Number
                    </Label>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">☎️</InputGroupAddon>
                      <Input
                        placeholder="Enter Consumer Number"
                        className="form-control"
                        id="input-mobilenumber"
                        type="number"
                        value={consumerNumber}
                        onChange={(e) => setConsumerNumber(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  {billDetails?.response_code ? (
                    <FormGroup>
                      <Card
                        style={{
                          backgroundColor: "#F8F8F8",
                          borderStyle: "solid",
                          borderColor: "#E0E0E0",
                        }}
                      >
                        <CardBody style={{ fontSize: "14px" }}>
                          <b>Name:</b>
                          <span style={{ float: "right" }}>
                            {billDetails.name}
                          </span>
                          <br></br>
                          <b>Due Date:</b>
                          <span style={{ float: "right" }}>
                            {billDetails.duedate}
                          </span>
                          <br></br>
                          <b>Amount:</b>
                          <span style={{ float: "right" }}>
                            ₹{billDetails.amount}
                          </span>
                          <br></br>
                        </CardBody>
                      </Card>
                    </FormGroup>
                  ) : null}
                  <FormGroup>
                    {billDetails?.response_code ? (
                      <Button
                        outline
                        color="primary"
                        type="button"
                        onClick={payBill}
                      >
                        PROCEED TO PAY
                      </Button>
                    ) : (
                      <Button color="primary" type="button" onClick={fetchBill}>
                        Fetch Bill
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BroadbandService;
