import useAxiosPrivate from "api/useAxiosPrivate";
import Table from "components/Elements/Table";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import swal from "sweetalert";

const OperatorCode = ({ updateOperatorCode, operatorCodes, operatorId }) => {
  return (
    <Input
      type="text"
      placeholder="Enter Operator Code"
      bsSize={"sm"}
      onChange={(e) => updateOperatorCode(operatorId, e.target.value)}
      value={operatorCodes.find((item) => item.operatorId === operatorId).code}
    />
  );
};

const AddOperatorCodeModal = ({
  show,
  toggle,
  data,
  apiData,
  fetchData,
  operators,
}) => {
  const [isSubmit, setIsSubmit] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const [companyName, setCompanyName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [operatorCodes, setOperatorCodes] = useState([]);
  const ADD_OPERATOR_CODE = "/operator-code/add";
  useEffect(() => {
    setOperatorCodes(
      operators.map((operator) => {
        let code = "";
        if (data) {
          console.log(data);
          code = data.find((item) => item.operatorId === operator.id);
          if (code) {
            code = code.operatorCode;
          }
        }
        return {
          operatorId: operator.id,
          code: code,
          serviceApiId: apiData.id,
        };
      })
    );
  }, [operators]);

  const submitForm = async (e) => {
    e.preventDefault();
    if (isSubmit) return;
    setIsSubmit(true);
    try {
      const response = await axiosPrivate.post(
        ADD_OPERATOR_CODE,
        operatorCodes
      );
      if (response.status == 200) {
        swal("Operator Codes Updated Successfully!", "", "success");
        toggle();
        fetchData();
      } else {
        throw Error(response.data);
      }
    } catch (err) {
      swal(err.response.data.message, "", "error");
    } finally {
      setIsSubmit(false);
    }
  };

  const updateOperatorCode = (operatorId, code) => {
    let temp = [];
    Object.assign(temp, operatorCodes);

    setOperatorCodes(
      temp.map((item) => {
        if (item.operatorId == operatorId) {
          item.code = code;
        }
        return item;
      })
    );
  };
  const columns = [
    {
      text: "Operator Name",
      dataField: "operatorName",
    },
    {
      text: "Operator Code",
      dataField: "operatorCode",
      isDummyField: true,
      formatExtraData: [updateOperatorCode, operatorCodes],
      formatter: (c, row, i, extraData) => {
        return (
          <OperatorCode
            operatorId={row.id}
            updateOperatorCode={extraData[0]}
            operatorCodes={extraData[1]}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        fullscreen=""
        // size="lg"
        isOpen={show}
        backdrop="static"
        keyboard
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Update Operator Code</ModalHeader>
        <ModalBody>
          <Table columns={columns} data={operators} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => submitForm(e)}>
            Update Opeator Codes
          </Button>{" "}
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddOperatorCodeModal;
