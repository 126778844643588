
import React from "react";
import { useLocation, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar";
import routes from "routes.js";
import useGetRoutes from "components/useGetRoutes";
import useAuth from "hooks/useAuth";
import useRefreshToken from "hooks/useRefreshToken";
import StatusToast from "components/StatusToast";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  // const refresh = useRefreshToken();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Home";
  };

  return (
    <>
      {/* {!auth?.accessToken ? <Redirect from="/admin/index" to="/auth" />: null} */}
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/rc-logo.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />

        <Switch>
          {useGetRoutes(routes, useAuth)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
        <StatusToast />
      </div>
    </>
  );
};

export default Admin;
