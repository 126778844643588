import { Redirect, Route } from "react-router-dom";
import useAuth from "hooks/useAuth";
const GetRoute = ({ component, accessToken, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        accessToken ? (
          component()
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
const PrivateRoute = ({ component, allowedRoles, name, ...rest }) => {
  const { auth } = useAuth();

  let accessToken;
  accessToken = auth?.accessToken;
  let roleId = auth?.roleId;
  if (allowedRoles && allowedRoles.includes(roleId)) {
    return (
      <GetRoute component={component} accessToken={accessToken} {...rest} />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/auth/login",
        }}
      />
    );
  }
};

export default PrivateRoute;
