import PrivateRoute from "./PrivateRoute";
import useAuth from "hooks/useAuth";
const useGetRoutes = (routes) => {
  return routes.map((prop, key) => {
    if (prop["subCategories"]) {
      const subCategories = prop.subCategories.map((prop) => {
        if (prop["subCategories"]) {
          const categories = prop.subCategories.map((prop) => {
            return (
              <PrivateRoute
                path={prop.layout + prop.path}
                allowedRoles={prop.allowedRoles}
                name={prop.name}
                component={() => {
                  return (
                    <prop.component
                      name={prop.name}
                      isPackageDependent={prop?.isPackageDependent || false}
                    />
                  );
                }}
                key={prop.name}
              />
            );
          });
          return categories;
        } else {
          return (
            <PrivateRoute
              path={prop.layout + prop.path}
              allowedRoles={prop.allowedRoles}
              name={prop.name}
              component={() => {
                return (
                  <prop.component
                    name={prop.name}
                    isPackageDependent={prop?.isPackageDependent || false}
                  />
                );
              }}
              key={prop.name}
            />
          );
        }
      });
      return subCategories;
    } else {
      return (
        <PrivateRoute
          path={prop.layout + prop.path}
          allowedRoles={prop.allowedRoles}
          name={prop.name}
          component={() => {
            return (
              <prop.component
                name={prop.name}
                isPackageDependent={prop?.isPackageDependent || false}
              />
            );
          }}
          key={prop.name}
        />
      );
    }
  });
};

export default useGetRoutes;
