import useAxiosPrivate from "api/useAxiosPrivate";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import swal from "sweetalert";

const AddTenantModal = ({ show, toggle, data, fetchTenant }) => {
  const [isSubmit, setIsSubmit] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const [companyName, setCompanyName] = useState("");
  const [hostname, sethostname] = useState("");
  const [tenantId, setTenantId] = useState("");
  const ADD_TENANT = "/tenant/add";

  const submitForm = async (e) => {
    e.preventDefault();
    if (isSubmit) return;
    setIsSubmit(true);
    if (tenantId === "" || companyName === "" || hostname === "") {
      setIsSubmit(false);
      swal("Enter All Details!", "", "error");
      return;
    }
    try {
      var payload = {
        companyName,
        tenantId,
        hostname: hostname,
      };
      const response = await axiosPrivate.post(ADD_TENANT, payload);
      if (response.status == 200) {
        swal("Tenant Added Successfully!", "", "success");
        setTenantId("");
        setCompanyName("");
        toggle();
        fetchTenant();
      } else {
        throw Error(response.data);
      }
    } catch (err) {
      swal(err?.response?.data?.message, "", "error");
    } finally {
      setIsSubmit(false);
    }
  };
  return (
    <div>
      <Modal
        fullscreen=""
        // size="lg"
        isOpen={show}
        backdrop="static"
        keyboard
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Add Tenant</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label htmlFor="input-tenant">Tenant ID</label>
            <Input
              className="form-control"
              placeholder="Enter Tenant ID"
              type="text"
              value={tenantId}
              onChange={(e) =>
                setTenantId(e.target.value.trimStart().replace(" ", "-"))
              }
              id="input-tenant"
              name="tenant"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="input-company">Company Name</label>
            <Input
              className="form-control"
              type="text"
              placeholder="Enter Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              id="input-company"
              name="company"
            />
          </FormGroup>{" "}
          <FormGroup>
            <label htmlFor="input-host">Host Name</label>
            <Input
              className="form-control"
              type="text"
              placeholder="Enter Host Name"
              value={hostname}
              onChange={(e) => sethostname(e.target.value)}
              id="input-host"
              name="host"
            />
          </FormGroup>{" "}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => submitForm(e)}>
            Add Tenant
          </Button>{" "}
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddTenantModal;
