import useAxiosPrivate from "api/useAxiosPrivate";
import { serviceTypes } from "config/constaints";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap";
import swal from "sweetalert";

const AddPackageModal = ({ show, toggle, data, fetchPackages }) => {
  const [isSubmit, setIsSubmit] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const [name, setName] = useState("");
  const [services, setServices] = useState([]);
  const [price, setPrice] = useState(0);
  const ADD_PACKAGE = "/packages/add";
  const EDIT_PACKAGE = "/packages/";

  const submitForm = async (e) => {
    e.preventDefault();
    if (isSubmit) return;
    setIsSubmit(true);
    if (name === "" || price === "" || services.length == 0) {
      setIsSubmit(false);
      swal("Enter All Details!", "", "error");
      return;
    }
    try {
      var payload = {
        name,
        services,
        price
      };
      setIsSubmit(false);
      const response = await axiosPrivate.post(data ? EDIT_PACKAGE + data?.id : ADD_PACKAGE, payload);
      if (response.status == 200) {
        swal(`Package ${data ? "Updated" : "Added"} Successfully!`, "", "success");
        setName("");
        setServices([]);
        toggle();
        fetchPackages();
      } else {
        throw Error(response.data);
      }
    } catch (err) {
      swal(err.response.data.message, "", "error");
    } finally {
      setIsSubmit(false);
    }
  };
  return (
    <div>
      <Modal
        fullscreen=""
        // size="lg"
        isOpen={show}
        backdrop="static"
        keyboard
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>{data ? "Edit" : "Add"} Package</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label htmlFor="input-name">Package Name</label>
            <Input
              className="form-control"
              placeholder="Enter Package Name"
              type="text"
              value={name}
              onChange={(e) =>
                setName(e.target.value)
              }
              id="input-name"
              name="name"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="input-price">Price</label>
            <Input
              className="form-control"
              type="text"
              placeholder="Enter Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              id="input-price"
              name="price"
            />
          </FormGroup>{" "}
          <FormGroup check>
            <label htmlFor="input-checkbox">Services</label>
            <Row md={3}>
              {serviceTypes.map(service =>
                <Col>
                  <Label check>
                    <Input type="checkbox" checked={services.includes(service)} onChange={e => {
                      if (e.target.checked) {
                        setServices([...services, service])
                      } else {
                        setServices(services.filter(item => item != service))
                      }
                    }
                    } />{' '}
                    {service}
                  </Label>
                </Col>
              )}
            </Row>
          </FormGroup>{" "}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => submitForm(e)}>
            {data ? "Update" : "Add"} Package
          </Button>{" "}
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddPackageModal;
