import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

import Table from "components/Elements/Table";

import Header from "components/Headers/Header.js";
import Tile from "components/Tile";
import { useEffect } from "react";
import swal from "sweetalert";
import useAxiosPrivate from "api/useAxiosPrivate";
import { roles } from "config/constaints";

const AdminDashboard = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const [reports, setReports] = useState({});
  const fetchReports = async () => {
    try {
      const response = await axiosPrivate.get("/reports/statistics");
      console.log(response);
      if (response?.status === 200) {
        setReports(response.data.data);
      }
    } catch (err) {
      console.log(err);
      swal("something went wrong!", err.message, "error");
    }
  };
  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row md={5}>
          <Col>
            <Tile
              title={"Pending Fund Request"}
              value={reports?.counts?.pending || 0}
              color="success"
            />
          </Col>
          <Col>
            <Tile
              title={"Pending Complaints"}
              value={reports?.counts?.pendingComplaints || 0}
              color="warning"
            />
          </Col>
          <Col>
            <Tile
              title={"Pending W2R"}
              value={reports?.counts?.wrong2right || 0}
              color="danger"
            />
          </Col>
          <Col>
            <Tile
              title={"KYC Request"}
              value={reports?.counts?.kycRequest || 0}
              color="primary"
            />
          </Col>
          <Col>
            <Tile
              title={"Signup Request"}
              value={reports?.counts?.kycRequest || 0}
              color="primary"
            />
          </Col>
        </Row>
        <Row md={3}>
          <Col md={6}>
            <Card>
              <CardHeader>
                <Row md={2}>
                  <Col>Balance Statistics</Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table
                  data={reports?.balances || []}
                  columns={[
                    {
                      text: "USERTYPE",
                      dataField: "_id",
                      formatter: (c) => {
                        return roles[c];
                      },
                    },
                    {
                      text: "recharge",
                      dataField: "recharge",
                      formatter: (c) => {
                        return c || 0;
                      },
                    },
                    {
                      text: "utility",
                      dataField: "utility",
                      formatter: (c) => {
                        return c || 0;
                      },
                    },
                    {
                      text: "aeps",
                      dataField: "aeps",
                      formatter: (c) => {
                        return c || 0;
                      },
                    },
                    {
                      text: "total",
                      dataField: "total",
                      formatter: (c) => {
                        return c || 0;
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={3} style={{ paddingLeft: "0px" }}>
            <Card>
              <CardHeader>Account Statistics</CardHeader>
              <CardBody>
                <Table
                  columns={[
                    {
                      text: "USERTYPE",
                      dataField: "_id",
                      formatter: (c) => {
                        return roles[c];
                      },
                    },
                    {
                      text: "users",
                      dataField: "count",
                    },
                  ]}
                  data={reports?.users}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={3} style={{ paddingLeft: "0px" }}>
            <Card>
              <CardHeader>Recharge Statistics</CardHeader>
              <CardBody>
                <Table
                  columns={[
                    {
                      text: "USERTYPE",
                      dataField: "_id",
                      formatter: (c) => {
                        return roles[c];
                      },
                    },
                    {
                      text: "recharges",
                      dataField: "count",
                    },
                  ]}
                  data={reports?.users}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminDashboard;
