import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAxiosPrivate from "api/useAxiosPrivate";
import Spinner from "react-bootstrap/Spinner";
import { serviceTypes } from "config/constaints";
import swal from "sweetalert";

const APIDropdown = ({ number, serviceName, value, onChange, apis }) => {
  return (
    <>
      <div>
        <label>Primary API {number}</label>
        <Input
          className="form-control"
          id="input-operator"
          size={"sm"}
          type="select"
          value={value}
          onChange={(e) =>
            onChange({
              serviceId: serviceName,
              value: e.target.value,
              apiType: number,
            })
          }
        >
          <option key={0}>Select API</option>
          {apis.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Input>
      </div>
    </>
  );
};

const ServiceRoutingTable = () => {
  const [data, setData] = useState([]);
  const [routings, setRoutings] = useState([]);
  const [apis, setApis] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const axios = useAxiosPrivate();

  const updateData = (payload) => {
    const tempdata = [...data];
    tempdata.map((item) => {
      if (item.serviceId === payload.serviceId) {
        if (item.apis.find((item) => item.apiType == payload.apiType)) {
          item.apis = item.apis.map((item) => {
            if (item.apiType === payload.apiType) {
              item.value = payload.value;
            }
            return item;
          });
        } else {
          item.apis.push({ apiType: payload.apiType, value: payload.value });
        }
      }
      return item;
    });
    setData(tempdata);
  };

  const columns = [
    {
      text: "service name",
      dataField: "serviceId",
    },
    {
      text: "primary apis",
      dataField: "apis",
      formatExtraData: [data, updateData, apis],
      formatter: (cell, row, i, extraData) => {
        const data = extraData[0];
        return (
          <>
            <Row md={4}>
              <Col>
                <APIDropdown
                  number={1}
                  apis={extraData[2]}
                  onChange={extraData[1]}
                  serviceName={row.serviceId}
                  value={
                    data
                      .find((item) => item.serviceId === row.serviceId)
                      .apis.find((item) => item.apiType === 1)?.value || 0
                  }
                />
              </Col>
              <Col>
                <APIDropdown
                  number={2}
                  apis={extraData[2]}
                  onChange={extraData[1]}
                  serviceName={row.serviceId}
                  value={
                    data
                      .find((item) => item.serviceId === row.serviceId)
                      .apis.find((item) => item.apiType === 2)?.value || 0
                  }
                />
              </Col>
              <Col>
                <APIDropdown
                  number={3}
                  apis={extraData[2]}
                  onChange={extraData[1]}
                  serviceName={row.serviceId}
                  value={
                    data
                      .find((item) => item.serviceId === row.serviceId)
                      .apis.find((item) => item.apiType === 3)?.value || 0
                  }
                />
              </Col>
              <Col>
                <APIDropdown
                  number={4}
                  apis={extraData[2]}
                  onChange={extraData[1]}
                  serviceName={row.serviceId}
                  value={
                    data
                      .find((item) => item.serviceId === row.serviceId)
                      .apis.find((item) => item.apiType === 4)?.value || 0
                  }
                />
              </Col>
            </Row>
            <Row md={4}>
              <Col>
                <APIDropdown
                  number={5}
                  apis={extraData[2]}
                  onChange={extraData[1]}
                  serviceName={row.serviceId}
                  value={
                    data
                      .find((item) => item.serviceId === row.serviceId)
                      .apis.find((item) => item.apiType === 5)?.value || 0
                  }
                />
              </Col>
              <Col>
                <APIDropdown
                  number={6}
                  apis={extraData[2]}
                  onChange={extraData[1]}
                  serviceName={row.serviceId}
                  value={
                    data
                      .find((item) => item.serviceId === row.serviceId)
                      .apis.find((item) => item.apiType === 6)?.value || 0
                  }
                />
              </Col>
              <Col>
                <APIDropdown
                  number={7}
                  apis={extraData[2]}
                  onChange={extraData[1]}
                  serviceName={row.serviceId}
                  value={
                    data
                      .find((item) => item.serviceId === row.serviceId)
                      .apis.find((item) => item.apiType === 7)?.value || 0
                  }
                />
              </Col>
              <Col>
                <APIDropdown
                  number={8}
                  apis={extraData[2]}
                  onChange={extraData[1]}
                  serviceName={row.serviceId}
                  value={
                    data
                      .find((item) => item.serviceId === row.serviceId)
                      .apis.find((item) => item.apiType === 8)?.value || 0
                  }
                />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];
  const fetchRoutings = async () => {
    try {
      const response = await axios.get("/routings/service-routing-list");
      const data = response?.data?.list;
      // if(data.response_code)
      if (data?.length) {
        setData(data);
      } else {
        setData(
          serviceTypes.map((serviceType) => {
            return {
              serviceId: serviceType,
              apis: [],
            };
          })
        );
      }
      setIsDataFetched(true);
    } catch (err) {
      console.error(err);
    }
  };

  const updateRouting = async () => {
    try {
      const response = await axios.post("/routings/service-routing", data);
      response.status === 200 &&
        swal("success", "Service APIs updated successfully!", "success");
    } catch (err) {
      swal("error", err.message, "error");
    }
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchAPIList = async () => {
      try {
        const response = await axios.get("/service-api/list");

        const data = response?.data?.list;
        // if(data.response_code)
        isMounted && setApis(data);
        isMounted && fetchRoutings();
      } catch (err) {
        console.error(err);
      }
    };
    fetchAPIList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Service Routing</h3>
              </CardHeader>

              <ToolkitProvider keyField="name" data={data} columns={columns}>
                {(props) => (
                  <>
                    <BootstrapTable
                      // wrapperClasses="table-responsive"
                      rowClasses="text-nowrap"
                      {...props.baseProps}
                      noDataIndication={
                        isDataFetched ? (
                          "Table is Empty"
                        ) : (
                          <Spinner animation="border" variant="primary" />
                        )
                      }
                      headerWrapperClasses="thead-light"
                      bodyClasses="align-items-center"
                      hover
                      bordered={false}
                      // condensed
                      bootstrap4
                    />
                  </>
                )}
              </ToolkitProvider>
              <Row>
                <span style={{ float: "none", margin: "0 auto" }}>
                  <Button color="primary" type="button" onClick={updateRouting}>
                    Save
                  </Button>
                </span>
              </Row>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ServiceRoutingTable;
