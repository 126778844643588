import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Col,
  Input,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "api/useAxiosPrivate";
import { axiosPrivate } from "api/axios";
import Spinner from "react-bootstrap/Spinner";

const PendingRechargeReportTable = () => {
  const [data, setData] = useState([]);
  const [circles, setCircles] = useState([]);
  const [operators, setOperators] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { auth } = useAuth();
  const [isDataFetched, setIsDataFetched] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }
    const controller = new AbortController();
    let isMounted = true;
    const fetchTransaction = async () => {
      try {
        const response = await axiosPrivate.get(
          "/reports/fetch-details?status=pending",
          {
            AbortSignal: controller.signal,
            params: { fromDate: startDate, endDate: endDate },
          }
        );
        console.log(response.data);
        isMounted && response.data.data && setData(response.data.data);
        setIsDataFetched(true);
      } catch (err) {
        console.log(err);
      }
    };

    fetchTransaction();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [startDate, endDate]);

  useEffect(() => {
    const controller = new AbortController();
    let isMounted = true;
    const fetchOperators = async () => {
      try {
        const response = await axiosPrivate.get("/operators/list", {
          AbortSignal: controller.signal,
        });
        console.log(response.data);
        isMounted && response.data?.list && setOperators(response.data.list);
      } catch (err) {
        console.error(err?.response ? err.response : err.data);
      }
    };
    const fetchCircles = async () => {
      try {
        const response = await axiosPrivate.get("/states/list");
        const data = response?.data?.list;
        isMounted && setCircles(data);
      } catch (err) {
        console.error(err);
      }
    };
    Promise.all([fetchOperators(), fetchCircles()]);
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const pagination = paginationFactory({
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    ),
    sizePerPageList: [
      { text: "5", value: 5 },
      {
        text: "10",
        value: 10,
      },
    ],
  });

  const { SearchBar } = Search;

  const columns = [
    {
      text: "Recharge ID",
      dataField: "ourTxnId",
    },

    {
      text: "Date & Time",
      dataField: "date",
    },
    {
      text: "User Details",
      dataField: "userId",
      isDummyField: true,
      formatExtraData: [],
      formatter: (c, row, i, extraData) => {
        return "Test";
      },
    },
    {
      text: "number",
      dataField: "number",
    },
    {
      text: "Operator",
      dataField: "operatorId",
      formatExtraData: operators,
      formatter: (c, row, i, operators) => {
        if (operators.length) {
          return (
            operators?.find((operator) => operator.id === c)?.operatorName ||
            "Invalid Operator"
          );
        }
        return "NA";
      },
    },
    {
      text: "circleId",
      dataField: "circleId",
      formatExtraData: circles,
      formatter: (c, row, i, circles) => {
        if (circles.length) {
          return (
            circles?.find((circle) => circle.id === c)?.name || "Invalid Circle"
          );
        }
        return "NA";
      },
    },
    {
      text: "amount",
      dataField: "amount",
      formatter: (c) => {
        return "₹" + c;
      },
    },
    {
      text: "status",
      dataField: "status",
    },
  ];

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Pending Recharge Logs</h3>
              </CardHeader>

              <ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Col className="text-center" xs="20">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 float-right"
                      >
                        <Form inline className="m-2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-start-date"
                            >
                              Start Date
                            </label>
                            <Input
                              type="date"
                              id="input-start-date"
                              name="start-date"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-end-date"
                            >
                              End Date
                            </label>
                            <Input
                              type="date"
                              id="input-end-date"
                              name="end-date"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <label htmlFor="input-search" className="mr-sm-2">
                              Search:
                            </label>
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              srText="Search:"
                              {...props.searchProps}
                            />
                          </FormGroup>
                        </Form>
                      </div>
                    </Col>
                    <BootstrapTable
                      // wrapperClasses="table-responsive"
                      rowClasses="text-nowrap"
                      {...props.baseProps}
                      noDataIndication={
                        isDataFetched || startDate == "" || endDate == "" ? (
                          "Table is Empty"
                        ) : (
                          <Spinner animation="border" variant="primary" />
                        )
                      }
                      headerWrapperClasses="thead-light"
                      bodyClasses="align-items-center"
                      pagination={pagination}
                      hover
                      bordered={false}
                      // condensed
                      bootstrap4
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PendingRechargeReportTable;
