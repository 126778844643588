import useAxiosPrivate from "api/useAxiosPrivate";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Form,
} from "reactstrap";
import swal from "sweetalert";

const AddRoutingModal = ({
  show,
  toggle,
  data,
  rechargeAPIs,
  operators,
  circles,
  users,
  loadData,
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [user, setUser] = useState(data?.userId || 0);
  const [operator, setOperator] = useState(data?.operatorId || 0);
  const [circle, setCircle] = useState(data?.circleId || 0);
  const [amounts, setAmounts] = useState(
    data?.amounts.reduce((amounts, item, index) => {
      if (index !== 0) {
        amounts += ",";
      }
      if (item.min === item.max) amounts += +item.min;
      else amounts += item.min + "-" + item.max;
      return amounts;
    }, "") || ""
  );
  const [apis, setApis] = useState([]);

  useEffect(() => {
    var numbers = [1, 2, 3, 4, 5, 6, 7, 8];
    if (data) {
      setApis(
        numbers.map((item) => {
          return {
            apiType: item,
            value: data.apis.find((api) => api.apiType === item)?.value || "0",
          };
        })
      );
    } else {
      setApis(
        numbers.map((item) => {
          return { apiType: item, value: "0" };
        })
      );
    }
  }, []);

  const updateApi = (apiType, value) => {
    var tempData = [...apis];
    tempData = tempData.map((item) => {
      if (item.apiType === apiType) {
        item.value = value;
      }
      return item;
    });
    setApis(tempData);
  };

  const axiosPrivate = useAxiosPrivate();
  // useEffect();
  const submitForm = async (e) => {
    e.preventDefault();
    if (isSubmit) return;
    try {
      if (!apis.find((item) => item.apiType == 1)) {
        return swal("Primary API 1 is Required!", "", "error");
      }
      var payload = {
        id: data?.id || null,
        userId: user || null,
        operatorId: operator,
        circleId: circle,
        priority: "high",
        amounts: amounts.split(",").map((item) => {
          if (item.includes("-")) {
            item = item.split("-");
            return { min: item[0], max: item[1] };
          } else if (item) {
            return { min: item, max: item };
          } else {
            return {
              min: 0,
              max: 0,
            };
          }
        }),
        apis: apis.filter((item) => item.value !== "0"),
      };
      setIsSubmit(true);
      const response = await axiosPrivate.post(
        data?.id ? "routings/" + data.id : "/routings/add",
        payload
      );
      if (response.data.success) {
        swal("Routing Added Successfully!", "", "success");
        loadData();
        toggle();
      } else if (response?.data?.message) {
        throw new Error(response.data.message);
      }
    } catch (err) {
      console.log(err);
      swal(err.message, "", "error");
    } finally {
      setIsSubmit(false);
    }
  };

  return (
    <div>
      <Modal
        fullscreen=""
        size="lg"
        isOpen={show}
        backdrop="static"
        keyboard
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>
          {data ? "Edit" : "Add"} Routing
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <label htmlFor="input-User">User</label>
                  <Input
                    className="form-control"
                    type="select"
                    defaultValue={user}
                    onChange={(e) => setUser(e.target.value)}
                    id="input-user"
                    name="user"
                  >
                    <option key="0" value="0">
                      Select User
                    </option>
                    {users.map((item) => {
                      return (
                        <option key={item.userId} value={item.id}>
                          {item.username}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <label htmlFor="input-operator">Operator</label>
                  <Input
                    className="form-control"
                    type="select"
                    defaultValue={operator}
                    onChange={(e) => setOperator(e.target.value)}
                    id="input-operator"
                    name="operator"
                  >
                    <option key="0" value="0">
                      Select Operator
                    </option>
                    {operators.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.operatorName}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <label htmlFor="input-circle">Circle</label>
                  <Input
                    className="form-control"
                    type="select"
                    defaultValue={circle}
                    onChange={(e) => setCircle(e.target.value)}
                    id="input-circle"
                    name="circle"
                  >
                    <option key="0" value="0">
                      Select Circle
                    </option>
                    {circles.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <label htmlFor="input-amount">Amount</label>
                  <Input
                    className="form-control"
                    type="text"
                    defaultValue={amounts}
                    onChange={(e) => setAmounts(e.target.value)}
                    id="input-amount"
                    placeholder="Enter Amount"
                    name="amount"
                  />
                </FormGroup>
              </Col>
            </Row>
            {apis.length ? (
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <label htmlFor="input-api-type">Primary API 1</label>
                    <Input
                      className="form-control"
                      type="select"
                      value={apis.find((item) => item.apiType === 1).value}
                      onChange={(e) => {
                        updateApi(1, e.target.value);
                      }}
                      id="input-primary-1"
                      name="primary-1"
                    >
                      <option key="0" value="0">
                        Select Primary API
                      </option>
                      {rechargeAPIs.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label htmlFor="input-api-type">Primary API 2</label>
                    <Input
                      className="form-control"
                      type="select"
                      defaultValue={
                        apis.find((item) => item.apiType === 2).value
                      }
                      onChange={(e) => {
                        updateApi(2, e.target.value);
                      }}
                      id="input-primary-2"
                      name="primary-2"
                    >
                      <option key="0" value="0">
                        Select Primary API
                      </option>
                      {rechargeAPIs.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label htmlFor="input-api-type">Primary API 3</label>
                    <Input
                      className="form-control"
                      type="select"
                      defaultValue={
                        apis.find((item) => item.apiType === 3).value
                      }
                      onChange={(e) => {
                        updateApi(3, e.target.value);
                      }}
                      id="input-primary-3"
                      name="primary-3"
                    >
                      <option key="0" value="0">
                        Select Primary API
                      </option>
                      {rechargeAPIs.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label htmlFor="input-api-type">Primary API 4</label>
                    <Input
                      className="form-control"
                      type="select"
                      defaultValue={
                        apis.find((item) => item.apiType === 4).value
                      }
                      onChange={(e) => {
                        updateApi(4, e.target.value);
                      }}
                      id="input-primary-4"
                      name="primary-4"
                    >
                      <option key="0" value="0">
                        Select Primary API
                      </option>
                      {rechargeAPIs.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label htmlFor="input-api-type">Primary API 5</label>
                    <Input
                      className="form-control"
                      type="select"
                      defaultValue={
                        apis.find((item) => item.apiType === 5).value
                      }
                      onChange={(e) => {
                        updateApi(5, e.target.value);
                      }}
                      id="input-primary-5"
                      name="primary-5"
                    >
                      <option key="0" value="0">
                        Select Primary API
                      </option>
                      {rechargeAPIs.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label htmlFor="input-api-type">Primary API 6</label>
                    <Input
                      className="form-control"
                      type="select"
                      defaultValue={
                        apis.find((item) => item.apiType === 6).value
                      }
                      onChange={(e) => {
                        updateApi(6, e.target.value);
                      }}
                      id="input-primary-6"
                      name="primary-6"
                    >
                      <option key="0" value="0">
                        Select Primary API
                      </option>
                      {rechargeAPIs.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label htmlFor="input-api-type">Primary API 7</label>
                    <Input
                      className="form-control"
                      type="select"
                      defaultValue={
                        apis.find((item) => item.apiType === 7).value
                      }
                      onChange={(e) => {
                        updateApi(7, e.target.value);
                      }}
                      id="input-primary-7"
                      name="primary-7"
                    >
                      <option key="0" value="0">
                        Select Primary API
                      </option>
                      {rechargeAPIs.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label htmlFor="input-api-type">Primary API 8</label>
                    <Input
                      className="form-control"
                      type="select"
                      defaultValue={
                        apis.find((item) => item.apiType === 8).value
                      }
                      onChange={(e) => {
                        updateApi(8, e.target.value);
                      }}
                      id="input-primary-8"
                      name="primary-8"
                    >
                      <option key="0" value="0">
                        Select Primary API
                      </option>
                      {rechargeAPIs.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => submitForm(e)}>
            {data ? "Save" : "Add"} Routing
          </Button>{" "}
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddRoutingModal;
