import axios from "api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { auth, setAuth } = useAuth();

  const refresh = async (auth2,setAuth2) => {
    // try{
    const payload = {
      token: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
    };
    const response = await axios.post("/UserAPI/refresh", payload);
    // }catch(err){
    //   console.log(err)
    // }
    console.log(auth)
    const newAuth = auth || auth2;//{};
    console.log(`newauth: ${JSON.stringify(newAuth)}`)
    newAuth.accessToken = response.data.token;
    newAuth.refreshToken = response.data.refreshToken;
    // newAuth.username = localStorage.getItem("username");
    // newAuth.userId = localStorage.getItem("userId")
    // newAuth.roleId = localStorage.getItem("roleId");
    if(setAuth){
      setAuth(newAuth);
    }else{
      setAuth2(newAuth);
    }
    localStorage.setItem("accessToken", response.data.token);
    localStorage.setItem("refreshToken", response.data.refreshToken);
    return response.data.token;
  };
  return refresh;
};
export default useRefreshToken;
