import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Col,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "api/useAxiosPrivate";
import swal from "sweetalert";
import AddOperatorModal from "components/Modals/AddOperatorModal";
import Spinner from "react-bootstrap/Spinner";
import { serviceTypes } from "config/constaints";

const OperatorSelectMenu = ({ row, fetchData }) => {
  const axiosPrivate = useAxiosPrivate();

  const [isOpen, setIsOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const toggleEditModal = () => setEditModal(!editModal);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const deleteOperator = async () => {
    try {
      const response = await axiosPrivate.delete(`/operators/${row.id}`);
      swal("Operator Deleted Successfully!", "", "success");
      fetchData();
    } catch (err) {
      swal(
        err.response.data.message
          ? err.response.data.message
          : "Something Went Wrong!",
        "",
        "error"
      );
    }
  };
  return (
    <>
      <ButtonDropdown
        isOpen={isOpen}
        direction="up"
        toggle={toggle}
        size="sm"
        color="primary"
      >
        <DropdownToggle caret color="primary"></DropdownToggle>
        <DropdownMenu>
          <DropdownItem header style={{ color: "#212529" }}>
            Action Menu
          </DropdownItem>
          <DropdownItem onClick={toggleEditModal}>Edit</DropdownItem>
          <DropdownItem onClick={deleteOperator}>Delete</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      {editModal === true ? (
        <AddOperatorModal
          toggle={toggleEditModal}
          show={editModal}
          data={row}
        />
      ) : null}
    </>
  );
};

const OperatorsTable = () => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };
  const [data, setData] = useState([]);
  const [serviceApiList, setServiceApiList] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const { balance } = useAuth();

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const fetchAPIList = async () => {
    try {
      const response = await axiosPrivate.get("/service-api/list", {
        AbortSignal: controller.signal,
      });
      response.data.list && setServiceApiList(response.data.list);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchOperators = async () => {
    try {
      const response = await axiosPrivate.get("/operators/list", {
        AbortSignal: controller.signal,
      });
      response.data.list && setData(response.data.list);
      setIsDataFetched(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // if (!balance) return;

    Promise.all([fetchAPIList(), fetchOperators()]);
    return () => {
      controller.abort();
    };
  }, [balance]);

  const pagination = paginationFactory({
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    ),
    sizePerPageList: [
      { text: "5", value: 5 },
      {
        text: "10",
        value: 10,
      },
    ],
  });

  const { SearchBar } = Search;

  const formatAction = (cell, row, i, extraData) => {
    return <OperatorSelectMenu row={row} fetchData={extraData[0]} />;
  };

  const columns = [
    {
      text: "#",
      isDummyField: true,
      dataField: "something",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "action",
      text: "Action",
      isDummyField: true,
      formatExtraData: [fetchOperators],
      formatter: formatAction,
    },
    {
      text: "Operator Name",
      dataField: "operatorName",
    },
    {
      text: "Service Type",
      dataField: "operatorType",
      formatExtraData: serviceTypes,
      formatter: (cell, row, i, serviceTypes) => {
        return serviceTypes.find((item) => item.toLowerCase() === cell);
      },
    },
    {
      text: "isActive",
      // isDummyField: true,
      dataField: "isActive",
      formatter: (cell) => {
        return cell ? "Active" : "InActive";
      },
    },
  ];

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Operators List</h3>
              </CardHeader>

              <ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Col className="text-center" xs="20">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 float-right"
                      >
                        <Form inline className="m-2">
                          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <label htmlFor="input-search" className="mr-sm-2">
                              Search:
                            </label>
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              srText="Search:"
                              {...props.searchProps}
                            />
                          </FormGroup>
                          <Button color="primary" size="sm" onClick={toggle}>
                            New
                          </Button>
                          {showModal ? (
                            <AddOperatorModal
                              show={showModal}
                              toggle={toggle}
                              fetchOperators={fetchOperators}
                              serviceApiList={serviceApiList}
                            />
                          ) : (
                            <></>
                          )}
                        </Form>
                      </div>
                    </Col>
                    <BootstrapTable
                      // wrapperClasses="table-responsive"
                      rowClasses="text-nowrap"
                      {...props.baseProps}
                      noDataIndication={
                        isDataFetched ? (
                          "Table is Empty"
                        ) : (
                          <Spinner animation="border" variant="primary" />
                        )
                      }
                      headerWrapperClasses="thead-light"
                      bodyClasses="align-items-center"
                      pagination={pagination}
                      hover
                      bordered={false}
                      // condensed
                      bootstrap4
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OperatorsTable;
