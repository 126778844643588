import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Col,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "api/useAxiosPrivate";
import swal from "sweetalert";
import AddOperatorFetchModal from "components/Modals/AddOperatorFetchModal";

const OperatorSelectMenu = ({ row, fetchData }) => {
  const axiosPrivate = useAxiosPrivate();

  const [isOpen, setIsOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const toggleEditModal = () => setEditModal(!editModal);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const deleteOperator = async () => {
    let result = await swal({
      // title:"Are you sure?",
      title: "Do you want to delete Fetch API?",
      text: "Press delete to continue.",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    });
    if (result === null) {
      return;
    }
    try {
      const response = await axiosPrivate.delete(`/extras/operator-fetch/${row._id}`);
      swal("Operator Fetch Deleted Successfully!", "", "success");
      fetchData();
    } catch (err) {
      swal(
        err?.response?.data?.message
          ? err.response.data.message
          : "Something Went Wrong!",
        "",
        "error"
      );
    }
  };
  return (
    <>
      <ButtonDropdown
        isOpen={isOpen}
        direction="up"
        toggle={toggle}
        size="sm"
        color="primary"
      >
        <DropdownToggle caret color="primary"></DropdownToggle>
        <DropdownMenu>
          <DropdownItem header style={{ color: "#212529" }}>
            Action Menu
          </DropdownItem>
          <DropdownItem onClick={toggleEditModal}>Edit</DropdownItem>
          <DropdownItem onClick={deleteOperator}>Delete</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      {editModal === true ? (
        <AddOperatorFetchModal
          toggle={toggleEditModal}
          show={editModal}
          data={row}
        />
      ) : null}
    </>
  );
};

const OperatorFetchTable = () => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };
  const [data, setData] = useState([]);

  const { balance } = useAuth();

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const fetchOperatorsPlan = async () => {
    try {
      const response = await axiosPrivate.get("/extras/operator-fetch/list", {
        AbortSignal: controller.signal,
      });
      console.log(response.data);
      response.data.list && setData(response.data.list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // if (!balance) return;

    Promise.all([fetchOperatorsPlan()]);
    return () => {
      controller.abort();
    };
  }, [balance]);

  const pagination = paginationFactory({
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    ),
    sizePerPageList: [
      { text: "5", value: 5 },
      {
        text: "10",
        value: 10,
      },
    ],
  });

  const { SearchBar } = Search;

  const formatAction = (cell, row, i, extraData) => {
    return <OperatorSelectMenu row={row} fetchData={extraData[0]} />;
  };

  const columns = [
    {
      text: "#",
      isDummyField: true,
      dataField: "something",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "action",
      text: "Action",
      isDummyField: true,
      formatExtraData: [fetchOperatorsPlan],
      formatter: formatAction,
    },
    {
      text: "Name",
      dataField: "name",
    },
    {
      text: "Fetch Url",
      // isDummyField: true,
      dataField: "fetchUrl"
    },
  ];

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Operator Fetch API</h3>
              </CardHeader>

              <ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Col className="text-center" xs="20">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 float-right"
                      >
                        <Form inline className="m-2">
                          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <label htmlFor="input-search" className="mr-sm-2">
                              Search:
                            </label>
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              srText="Search:"
                              {...props.searchProps}
                            />
                          </FormGroup>
                          <Button color="primary" size="sm" onClick={toggle}>
                            New
                          </Button>
                          <AddOperatorFetchModal
                            show={showModal}
                            toggle={toggle}
                            fetchOperatorsPlan={fetchOperatorsPlan}
                          />
                        </Form>
                      </div>
                    </Col>
                    <BootstrapTable
                      // wrapperClasses="table-responsive"
                      rowClasses="text-nowrap"
                      {...props.baseProps}
                      noDataIndication="Table is Empty"
                      headerWrapperClasses="thead-light"
                      bodyClasses="align-items-center"
                      pagination={pagination}
                      hover
                      bordered={false}
                      // condensed
                      bootstrap4
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OperatorFetchTable;
