import useAuth from "hooks/useAuth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";

const ServiceValidate = ({ serviceName }) => {
  const { user } = useAuth();
  const isAllowed = user?.packageServices?.includes(serviceName);
  const history = useHistory();
  const redirect = async () => {
    await swal({
      title: "Error!",
      icon: "error",
      text: "You do not have access to this service!",
      closeOnClickOutside: false,
      backdrop: true,
      dangerMode: true,
    });
    history.push("/dashboard");
  };
  if (!isAllowed) {
    redirect();
  }
  return <></>;
};

export default ServiceValidate;
