import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Col,
  Input,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "api/useAxiosPrivate";
import { axiosPrivate } from "api/axios";
import Spinner from "react-bootstrap/Spinner";

function getDefaultDate() {
  const date = new Date();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const year = date.getFullYear();

  const shortDate = `${year}-${month}-${day}`;

  return shortDate;
}

const LiveRechargeLogsTable = () => {
  const [data, setData] = useState([]);
  const [circles, setCircles] = useState([]);
  const [operators, setOperators] = useState([]);
  const { auth } = useAuth();
  const [isDataFetched, setIsDataFetched] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const controller = new AbortController();
    let isMounted = true;
    const fetchTransaction = async () => {
      try {
        const response = await axiosPrivate.get("/reports/recharge-logs", {
          AbortSignal: controller.signal,
        });
        console.log(response.data);
        isMounted && response?.data?.data && setData(response.data.data);
        setIsDataFetched(true);
        // isMounted && response.data.Data && fetchOperators();
      } catch (err) {
        console.log(err);
      }
    };

    const fetchOperators = async () => {
      try {
        const response = await axiosPrivate.get("/operators/list", {
          AbortSignal: controller.signal,
        });
        console.log(response.data);
        isMounted && response.data?.list && setOperators(response.data.list);
      } catch (err) {
        console.error(err?.response ? err.response : err.data);
      }
    };
    const fetchCircles = async () => {
      try {
        const response = await axiosPrivate.get("/states/list");
        const data = response?.data?.list;
        // if(data.response_code)
        isMounted && setCircles(data);
        isMounted && fetchOperators();
      } catch (err) {
        console.error(err);
      }
    };
    Promise.all([fetchOperators(), fetchTransaction(), fetchCircles()]);
    setInterval(() => {
      fetchTransaction();
    }, 10000);
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const pagination = paginationFactory({
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    ),
    sizePerPageList: [
      { text: "5", value: 5 },
      {
        text: "10",
        value: 10,
      },
    ],
  });

  const { SearchBar } = Search;

  const columns = [
    {
      text: "Recharge ID",
      dataField: "ourTxnId",
    },
    {
      text: "Date & Time",
      dataField: "date",
      formatter: (c) => {
        return new Date(c).toLocaleString("en-IN", {
          day: "2-digit",
          year: "2-digit",
          month: "2-digit",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      },
    },
    {
      text: "User Details",
      dataField: "logType",
    },
    {
      text: "Operator",
      dataField: "operatorId",
      style: { whiteSpace: "normal" },
      formatExtraData: [operators],
      formatter: (operatorId, r, i, extraData) => {
        var operator = extraData[0].find((item) => item.id == operatorId);
        return operator?.operatorName || "NA";
      },
    },
    {
      text: "Circle",
      dataField: "circleId",
      formatExtraData: [circles],
      formatter: (c, r, i, extraData) => {
        var circle = extraData[0].find((item) => item.id == c);
        return circle?.name || "NA";
      },
    },
    {
      text: "Mobile Number",
      dataField: "number",
    },
    {
      text: "Amount",
      dataField: "amount",
    },
    {
      text: "Status",
      dataField: "status",
    },
  ];

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Live Recharge Logs</h3>
              </CardHeader>

              <ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Col className="text-center" xs="20">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 float-right"
                      >
                        <Form inline className="m-2">
                          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <label htmlFor="input-search" className="mr-sm-2">
                              Search:
                            </label>
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              srText="Search:"
                              {...props.searchProps}
                            />
                          </FormGroup>
                        </Form>
                      </div>
                    </Col>
                    <BootstrapTable
                      // wrapperClasses="table-responsive"
                      rowClasses="text-nowrap"
                      {...props.baseProps}
                      noDataIndication={
                        isDataFetched ? (
                          "Table is Empty"
                        ) : (
                          <Spinner animation="border" variant="primary" />
                        )
                      }
                      headerWrapperClasses="thead-light"
                      bodyClasses="align-items-center"
                      pagination={pagination}
                      hover
                      bordered={false}
                      // condensed
                      bootstrap4
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default LiveRechargeLogsTable;
