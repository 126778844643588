import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Col,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  ButtonDropdown,
  Badge,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "api/useAxiosPrivate";
import AddPackageModal from "components/Modals/AddPackageModal";
import swal from "sweetalert";
import { serviceTypes } from "config/constaints";
import { colorStyles } from "config/constaints";
import Spinner from "react-bootstrap/Spinner";

const PackageSelectMenu = ({
  row,
  fetchData,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [showEditModal, setShowEditModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal)
  }

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const DeletePackage = async () => {
    try {
      const response = await axiosPrivate.delete(`/packages/${row.id}`);
      swal("Package Deleted Successfully!", "", "success");
      fetchData();
    } catch (err) {
      swal(err.response.data.message, "", "error");
    }
  };
  return (
    <>
      <ButtonDropdown
        isOpen={isOpen}
        direction="up"
        toggle={toggle}
        size="sm"
        color="primary"
      >
        <DropdownToggle caret color="primary"></DropdownToggle>
        <DropdownMenu>
          <DropdownItem header style={{ color: "#212529" }}>
            Action Menu
          </DropdownItem>
          <DropdownItem onClick={toggleEditModal}>Edit</DropdownItem>
          <DropdownItem onClick={DeletePackage}>Delete</DropdownItem>

        </DropdownMenu>
      </ButtonDropdown>

      {showEditModal === true ? (
        <AddPackageModal
          toggle={toggleEditModal}
          show={showEditModal}
          data={row}
        />
      ) : null}
    </>
  );
};

const PackageTable = () => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };
  const [data, setData] = useState([]);

  const { balance } = useAuth();
  const [isDataFetched, setIsDataFetched] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const fetchAPIList = async () => {
    try {
      const response = await axiosPrivate.get("/packages/list", {
        AbortSignal: controller.signal,
      });
      console.log(response.data);
      response.data.data && setData(response.data.data);
      setIsDataFetched(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // if (!balance) return;

    fetchAPIList();
    return () => {
      controller.abort();
    };
  }, [balance]);

  const pagination = paginationFactory({
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    ),
    sizePerPageList: [
      { text: "5", value: 5 },
      {
        text: "10",
        value: 10,
      },
    ],
  });

  const { SearchBar } = Search;

  const formatAction = (cell, row, i, extraData) => {
    return (
      <PackageSelectMenu
        row={row}
        fetchData={extraData[0]}
      />
    );
  };

  const columns = [
    {
      text: "#",
      isDummyField: true,
      dataField: "something",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "action",
      text: "Action",
      isDummyField: true,
      formatExtraData: [fetchAPIList],
      formatter: formatAction,
    },
    {
      text: "Package Name",
      dataField: "name",
    },
    {
      text: "Services",
      dataField: "services",
      formatter: (c, row) => {
        return c.map(item => <Badge color={colorStyles[(Math.floor(Math.random() * colorStyles.length))]} pill>{item}</Badge>)
      }
    },
    {
      text: "Price",
      dataField: "price",
      formatter: (c) => {
        return "₹" + c
      }

    },
    {
      text: "isActive",
      // isDummyField: true,
      dataField: "isActive",
      // formatter: (cell, row) => {
      //   return <SwitchBox initial={false} updater={inSwitchUpdater} />;
      // },
    },
  ];

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Package List</h3>
              </CardHeader>

              <ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Col className="text-center" xs="20">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1 float-right"
                      >
                        <Form inline className="m-2">
                          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <label htmlFor="input-search" className="mr-sm-2">
                              Search:
                            </label>
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              srText="Search:"
                              {...props.searchProps}
                            />
                          </FormGroup>
                          <Button color="primary" size="sm" onClick={toggle}>
                            New
                          </Button>
                          <AddPackageModal
                            show={showModal}
                            toggle={toggle}
                            fetchPackages={fetchAPIList}
                          ></AddPackageModal>
                        </Form>
                      </div>
                    </Col>
                    <BootstrapTable
                      // wrapperClasses="table-responsive"
                      rowClasses="text-nowrap"
                      {...props.baseProps}
                      noDataIndication={
                        isDataFetched ? (
                          "Table is Empty"
                        ) : (
                          <Spinner animation="border" variant="primary" />
                        )
                      }
                      headerWrapperClasses="thead-light"
                      bodyClasses="align-items-center"
                      pagination={pagination}
                      hover
                      bordered={false}
                      // condensed
                      bootstrap4
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PackageTable;
